import React, { useCallback, useMemo } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { useBasicConfig } from "./config";

export interface User {
  sub?: string,
  name?: string,
  givenName?: string,
  familyName?: string,
  picture?: string,
  email?: string,
  isSupport?: boolean,
}

export const mockUser: User = {
  name: "Ada Lovelace",
  givenName: "Ada",
  familyName: "Lovelace",
  picture: "https://media.sciencephoto.com/image/c0047370/800wm/C0047370-Ada_Lovelace.jpg",
  email: "ada@love.lace",
  sub: "its-ada",
  isSupport: false,
};
export const mockGetAccessToken = (): Promise<string> => Promise.resolve("mockAccessToken");
export const mockLogout = (): void => {};

interface AppState {
  returnTo?: string,
}

export interface Auth {
  user: User,
  getAccessToken: () => Promise<string>,
  logout: () => void,
}

export const useAuth = (): Auth => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks,@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    const { user: baseUser, getAccessTokenSilently: getAccessToken, logout: baseLogout } = useAuth0();
    const logout = useCallback(() => {
      const options: AppState = { returnTo: window.location.origin };
      return baseLogout(options);
    }, [baseLogout]);
    const user = useMemo<User>(() => ({
      sub: baseUser?.sub,
      name: baseUser?.name,
      givenName: baseUser?.given_name,
      familyName: baseUser?.family_name,
      picture: baseUser?.picture,
      email: baseUser?.email,
    }), [baseUser]);
    return { user, getAccessToken, logout };
  } catch {
    // No Auth0Provider - must be in mock auth mode
    return {
      user: mockUser,
      getAccessToken: mockGetAccessToken,
      logout: mockLogout,
    };
  }
};

export interface AuthFailureProps {
  readonly error: string,
  readonly reauthenticate: () => void,
}

interface AuthGateProps {
  readonly children?: React.ReactNode,
  readonly authenticatingComponent: React.ComponentType,
  readonly authFailureComponent: React.ComponentType<AuthFailureProps>,
}

function AuthGate({
  children,
  authenticatingComponent,
  authFailureComponent,
}: AuthGateProps): React.ReactElement {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();
  const { user, logout } = useAuth();
  React.useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      // path, query & hash
      const appState: AppState = { returnTo: window.location.href.replace(window.location.origin, "") };
      /* eslint-disable-next-line no-void */ // intentionally hanging promise
      void loginWithRedirect({ appState });
    }
  }, [isLoading, isAuthenticated, error, loginWithRedirect]);
  if (error) {
    const AuthFailureComponent = authFailureComponent;
    return (<AuthFailureComponent error={error.message} reauthenticate={logout} />);
  }
  if (isLoading || !user || !isAuthenticated) {
    const AuthenticatingComponent = authenticatingComponent;
    return (<AuthenticatingComponent />);
  }
  return (<>{children}</>);
}

export interface AuthConfig {
  readonly clientId: string,
  readonly domain: string,
  readonly audience: string,
}
export interface AuthProviderProps extends AuthGateProps {
  readonly mock?: boolean,
}
export function AuthProvider({
  children,
  authenticatingComponent,
  authFailureComponent,
  mock,
}: AuthProviderProps): React.ReactElement {
  const { auth: { domain, clientId, audience } } = useBasicConfig();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { replace: routeTo } = useHistory();
  const onRedirectCallback = useCallback(({ returnTo }: AppState) => {
    routeTo(returnTo ?? "/");
  }, [routeTo]);
  return mock === true ? (<>{children}</>) : (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      cacheLocation="localstorage"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      <AuthGate
        authenticatingComponent={authenticatingComponent}
        authFailureComponent={authFailureComponent}
      >
        {children}
      </AuthGate>
    </Auth0Provider>
  );
}
