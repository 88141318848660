import styled, { css } from "styled-components/macro";
import { NavLink, Link } from "react-router-dom";
import * as React from "react";
import { PlayArrow } from "@styled-icons/material-outlined";

const Box = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  transition: all ease 0.3s, margin-left ease 1s, border-left ease 1s;
  border: 1px solid ${(props) => props.theme.grey};
  height: 62px;
  .collapsed & {
    height: ${(props) => 47 + props.theme.pad}px;
  }
  &:hover {
    border-color: ${(props) => props.theme.blueLight};
    color: ${(props) => props.theme.white};
  }
  &:hover > * {
    opacity: 1;
  }
`;

const Main = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${(props) => props.theme.pad * 3}px;
  transition: all ease 0.3s, height ease 1s, color ease 0.05s;
  white-space: nowrap;
  overflow: visible;
  width: 100%;
  text-decoration: none;
  color: inherit;
  position:absolute;
  left:0;
  &.active {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.blue};
  }
  .collapsed & {
    height: ${(props) => 45 + props.theme.pad}px;
  }
`;

const Icon = styled.div`
  transition: all ease 0.3s, width ease 1s, height ease 1s;
  margin: 0 ${(props) => props.theme.pad}px;
  display: inline-block;
  width: 2rem;
  color: inherit;
  & svg {
    width:30px;
  }
  & > svg circle, & > svg path {
    transition: stroke-width ease 1s;
    stroke-width: 6%;
  }
  .collapsed & {
    margin: 0 28px;
    & > svg circle, & > svg path {
      stroke-width: 3%;
    }
  }
`;

const Text = styled.div`
  flex: auto 1 1;
  color: inherit;
  transition: all ease 0.3s, opacity ease 1s;
  opacity: 1;
  .collapsed & {
    opacity: 0;
  }
`;

const playStyle = css`
  flex: auto 0 0;
  padding: ${(props) => (props.theme.pad) - 1}px;
  color: ${(props) => props.theme.white};
  background-color: #141b2500;
  transition: all ease 0.3s !important;
  opacity: 0;
  position:absolute;
  right:0;
  .collapsed & {
    display:none;
  }
  &:hover {
    background-color: ${(props) => props.theme.blueDark};
  }
`;

const PlayLink = styled(Link)`
  ${playStyle}
`;

const PlayAnchor = styled.a`
  ${playStyle}
`;

interface Props {
  readonly to?: React.ComponentProps<typeof NavLink>["to"],
  readonly href?: string,
  readonly direct?: string,
  readonly directExternal?: boolean,
  readonly icon: React.ReactNode,
  readonly text: string,
  readonly className?: string,
  readonly testid?: string,
}

export function NavigationLink({ to, href, direct, directExternal, icon, text, className, testid }: Props): React.ReactElement {
  return (
    <Box data-testid={testid} className={className}>
      <Main to={to!} href={href} as={href ? "a" : undefined} activeClassName="active">
        <Icon title={text}>{icon}</Icon>
        <Text>{text}</Text>
      </Main>
      {direct && directExternal && (<PlayAnchor href={direct} target="_blank"><PlayArrow size={18} /></PlayAnchor>)}
      {direct && !directExternal && (<PlayLink to={direct}><PlayArrow size={18} /></PlayLink>)}
    </Box>
  );
}
