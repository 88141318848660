import styled from "styled-components/macro";
import * as React from "react";

const SectionHeader = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.blueLight};
  font-size: small;
  white-space: nowrap;
  padding: ${(props) => props.theme.dropdownPad}px;
`;

interface Props {
  readonly children?: React.ReactNode,
  readonly className?: string,
  readonly testid?: string,
}

export function DropDownSectionHeader({ children, className, testid }: Props): React.ReactElement {
  return (<SectionHeader className={className} data-testid={testid}>{children}</SectionHeader>);
}
