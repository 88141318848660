import { AddCommentRequest, DataPrivacyData, DataTransparencyData, ReportDto, ReportId, Step, SummaryNotesRequest, SystemEqualityData, SystemEqualityFinal, SystemPurposeData, SystemTransparencyData } from "../../shared/dtos";
import { extract, headers } from "../app/fetchUtils";

export async function fetchReport(id: ReportId, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  const token = await getAccessToken();
  const response = await fetch(`/api/reports/${id}`, {
    method: "GET",
    headers: headers(token),
  });
  return extract<ReportDto>(response);
}
export async function putComment(id: ReportId, request: AddCommentRequest, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  const token = await getAccessToken();
  const response = await fetch(`/api/reports/${id}/comment`, {
    method: "POST",
    headers: headers(token),
    body: JSON.stringify(request),
  });
  return extract<ReportDto>(response);
}

async function putStep<TIn>(id: ReportId, step: Step, request: TIn, getAccessToken: () => Promise<string>, final?: boolean): Promise<ReportDto> {
  const token = await getAccessToken();
  const response = await fetch(`/api/reports/${id}/${step}${final ? "/final" : ""}`, {
    method: "POST",
    headers: headers(token),
    body: JSON.stringify(request),
  });
  return extract<ReportDto>(response);
}

export function putSystemPurposeStep(id: ReportId, request: SystemPurposeData, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  return putStep(id, "systemPurpose", request, getAccessToken);
}

export function putDataTransparencyStep(id: ReportId, request: DataTransparencyData, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  return putStep(id, "dataTransparency", request, getAccessToken);
}

export function putDataPrivacyStep(id: ReportId, request: DataPrivacyData, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  return putStep(id, "dataPrivacy", request, getAccessToken);
}

export function putSystemTransparencyStep(id: ReportId, request: SystemTransparencyData, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  return putStep(id, "systemTransparency", request, getAccessToken);
}

export function putSystemEqualityStep(id: ReportId, request: SystemEqualityData, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  return putStep(id, "systemEquality", request, getAccessToken);
}

export function putSystemEqualityFinal(id: ReportId, request: SystemEqualityFinal, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  return putStep(id, "systemEquality", request, getAccessToken, true);
}

export async function putSummaryNotes(id: ReportId, request: SummaryNotesRequest, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  const token = await getAccessToken();
  const response = await fetch(`/api/reports/${id}/summaryNotes`, {
    method: "POST",
    headers: headers(token),
    body: JSON.stringify(request),
  });
  return extract<ReportDto>(response);
}
