import * as React from "react";
import { useParams } from "react-router-dom";
import { ReportId } from "../../../shared/dtos";
import { useReport } from "../../report/useReport";
import { AsyncPage } from "../../app/AsyncPage";
import { DataTransparencyCheckPage } from "./DataTransparencyCheckPage";

export function DataTransparencyCheckPageContainer(): React.ReactElement {
  const { reportId } = useParams<{ reportId: ReportId }>();
  const { report, addComment, runDataTransparencyStep } = useReport(reportId);
  return (
    <AsyncPage
      waitFor={report}
      component={DataTransparencyCheckPage}
      light
      addComment={addComment}
      runDataTransparencyStep={runDataTransparencyStep}
    />
  );
}
