import * as React from "react";
import { LoadingPage } from "./LoadingPage";
import { NotFoundPage } from "./NotFoundPage";
import { ErrorPage } from "./ErrorPage";
import { Async, AsyncProps } from "../types";

interface Props<T, P> {
  readonly waitFor: Async<T>,
  readonly component: React.ComponentType<AsyncProps<T> & P>,
  readonly light?: boolean,
}
export function AsyncPage<T, P>({ waitFor, component, light, ...rest }: Props<T, P> & Omit<P, "value" | "waitFor" | "component" | "light">): React.ReactElement {
  const Component: React.ComponentType<AsyncProps<T> & P> = component;
  if (waitFor.loading) {
    return (<LoadingPage light={light} />);
  }
  if (waitFor.error) {
    if (waitFor.error.status === 404) {
      return (<NotFoundPage />);
    }
    return (<ErrorPage>{waitFor.error.message}</ErrorPage>);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (<Component {...rest} value={waitFor.value!} />);
}
