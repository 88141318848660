import * as React from "react";
import styled from "styled-components/macro";

const Frame = styled.div<{ seemless: boolean, light?: boolean }>`
  position: relative;
  padding: ${(props) => (props.seemless ? 0 : (props.theme.pad * 3.5))}px;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  @media print {
    display: block;
    height: auto;
    overflow-y: revert;
    flex-basis: auto;
  }
  ${(props) => props.light && `
    background-color: white;
  `}
`;

const Inner = styled.div<{ center: boolean, width: number }>`
  width:100%;
  max-width: 1800px;
  text-align: ${(props) => (props.center ? "center" : "inherit")};
  margin: ${(props) => (props.center ? "auto" : "inherit")};
`;

export type PageMode = "center" | "seemless" | "narrow" | "wide";

interface Props {
  readonly children?: React.ReactNode,
  readonly testid: string,
  readonly mode?: PageMode,
  readonly light?: boolean,
  readonly outer?: React.ReactNode,
}

const Feedback = styled.a`
  position: fixed;
  right: 0;
  bottom: 30%;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  text-decoration: none;
  z-index: 101;
  color: white;
  background-color: ${(props) => props.theme.blue};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.blueLight};
    & > div {
      padding-bottom: 12px;
    }
  }
  border: 1px solid ${(props) => props.theme.blue};
  & > div {
    transition: padding-bottom 0.3s ease;
    border: 4px solid white;
    border-bottom-width: 0;
    padding: 6px 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  border-bottom-width: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: -2px -2px 6px 2px #00000066;
`;

export function PageFrame({ children, mode = "wide", testid, light, outer }: Props): React.ReactElement {
  return (
    <Frame seemless={mode === "seemless"} light={light} data-testid={testid}>
      <Feedback href="https://docs.google.com/forms/d/e/1FAIpQLSeC2RwvrfhZcnnxW6QJs5MO8ZAh0fBs1287dB8xyNdDz5te6w/viewform" target="_blank"><div>Give Feedback</div></Feedback>
      {outer}
      {mode === "seemless" ? children : (
        <Inner center={mode === "center"} width={mode === "narrow" ? 800 : 1280}>
          {children}
        </Inner>
      )}
    </Frame>
  );
}
