import { TextField } from "@material-ui/core";
import * as React from "react";
import { useField } from "formik";
import { FormControl } from "./FormControl";

interface Props {
  readonly name: string,
  readonly label: string,
  readonly explanation?: React.ReactNode,
  readonly variant?: "standard" | "filled" | "outlined",
  readonly children: React.ReactNode,
}
export function SelectInput({ name, label, explanation, children, variant = "outlined" }: Props): React.ReactElement {
  const [{ value, onChange }, { touched, error }] = useField<string>(name);
  const inner = (
    <TextField
      id={name}
      name={name}
      label={label}
      fullWidth
      onChange={onChange}
      value={value}
      error={touched && Boolean(error)}
      helperText={touched && error}
      variant={variant}
      select
    >
      {children}
    </TextField>
  );
  return !explanation ? inner : (
    <FormControl explanation={explanation}>
      {inner}
    </FormControl>
  );
}
