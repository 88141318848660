import * as React from "react";
import { useTranslation } from "./i18n";

interface RootDocumentTitleProps {
  readonly companyNameKey: string,
  readonly appNameKey: string,
  readonly children: React.ReactNode,
}
export function RootDocumentTitle({ companyNameKey, appNameKey, children }: RootDocumentTitleProps): React.ReactElement {
  const { t } = useTranslation();
  React.useEffect(() => {
    const initial = document.title;
    document.title = `${t(companyNameKey)} · ${t(appNameKey)} `;
    return (): void => {
      document.title = initial;
    };
  }, [companyNameKey, appNameKey, t]);
  return (<>{children}</>);
}

interface DocumentTitleProps {
  readonly title: string,
  readonly children: React.ReactNode,
}
export function DocumentTitle({ title, children }: DocumentTitleProps): React.ReactElement {
  React.useEffect(() => {
    const initial = document.title;
    document.title = `${document.title} · ${title}`;
    return (): void => {
      document.title = initial;
    };
  }, [title]);
  return (<>{children}</>);
}
