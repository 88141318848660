import * as React from "react";
import { FairnessMetric, ReportStatus, SystemEqualityData, SystemEqualityFinal, SystemEqualityResult } from "../../../shared/dtos";
import { useTranslation } from "../../../mf/utils/i18n";
import { ReportSectionProps } from "../../app/CheckLayout";
import { SystemEqualityFinalForm } from "./SystemEqualityFinalForm";
import { SystemEqualityReportContent } from "./SystemEqualityReportContent";
import { ColumnStats } from "../../app/ColumnStats";

const fairnessMetricNames: { [val in FairnessMetric]: string } = {
  "equal-opportunity": "app:SYSTEM_EQUALITY_FIELD_FAIRNESS_METRIC_OPTION_EQUAL_OPPORTUNITY",
  "demographic-parity": "app:SYSTEM_EQUALITY_FIELD_FAIRNESS_METRIC_OPTION_DEMOGRAPHIC_PARITY",
  "average-fairness": "app:SYSTEM_EQUALITY_FIELD_FAIRNESS_METRIC_OPTION_AVERAGE_FAIRNESS",
};

export function SystemEqualityReportSection({ data, showFinal, submitFinal }: ReportSectionProps<SystemEqualityData, SystemEqualityResult, SystemEqualityFinal>): React.ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t("app:REPORT_TEXT_SUBSECTION_CONFIGURATION")}</h4>
      {data.status === ReportStatus.PENDING || data.status === ReportStatus.RUNNING || data.status === ReportStatus.ERROR || (data.status === ReportStatus.PENDING_FINAL && !showFinal) ? (
        <p><em>{t("app:REPORT_TEXT_CHECK_INCOMPLETE")}</em></p>
      ) : (
        <>
          <p>
            {t("app:SYSTEM_EQUALITY_FIELD_DATASET_LABEL")}: {data.in!.dataset.name || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_EQUALITY_FIELD_FAIRNESS_METRIC_LABEL")}: {t(fairnessMetricNames[data.in!.fairnessMetric] || "app:OPTIONAL_FIELD_FALLBACK")}<br />
            {data.in!.fairnessMetric === "equal-opportunity" && <>{t("app:SYSTEM_EQUALITY_FIELD_LABEL_COLUMN_LABEL")}: {data.in!.labelColumn || t("app:OPTIONAL_FIELD_FALLBACK")}<br /></>}
            {t("app:SYSTEM_EQUALITY_FIELD_PREDICTION_COLUMN_LABEL")}: {data.in!.predictionColumn || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {data.in!.fairnessMetric !== "average-fairness" && <>{t("app:SYSTEM_EQUALITY_FIELD_PRIVILEGED_LABEL_LABEL")}: {data.in!.privilegedLabel || t("app:OPTIONAL_FIELD_FALLBACK")}<br /></>}
            {data.in!.dataset.columns!.map((column) => (
              <>
                <ColumnStats name={column} data={data.in!.dataset.body!} left />
                {t("app:SYSTEM_EQUALITY_FIELD_PROTECTED_ATTRIBUTE_LABEL")}: {data.in!.protectedAttribute[column] ? t("app:TRUE_LABEL") : t("app:FALSE_LABEL")}<br />
              </>
            ))}
          </p>
          <h4>{t("app:REPORT_TEXT_SUBSECTION_SUMMARY")}</h4>
          <>
            {showFinal ? (
              <SystemEqualityFinalForm data={data} onSubmitFinal={submitFinal!} />
            ) : (
              <SystemEqualityReportContent data={data} />
            )}
          </>
        </>
      )}
    </>
  );
}
