import React, { useContext } from "react";
import { AuthConfig } from "./auth";
import { I18nConfig } from "./i18n";
import { SupportConfig } from "./support";

export interface BasicConfig {
  readonly version: string,
  auth: AuthConfig,
  i18n: I18nConfig,
  support: SupportConfig,
}

// In reality this will be something that extends BasicConfig
export const ConfigContext = React.createContext<BasicConfig | null>(null);

// Basic config version for use in /mf/
export const useBasicConfig = (): BasicConfig => useContext(ConfigContext)!;

interface ConfigProviderProps {
  readonly children?: React.ReactNode,
}

export function configure<T extends BasicConfig>(config: T): { ConfigProvider: React.ComponentType, useConfig: () => T } {
  const useConfig = (): T => useBasicConfig() as T;
  function ConfigProvider({ children }: ConfigProviderProps): React.ReactElement {
    return (
      <ConfigContext.Provider value={config}>
        {children}
      </ConfigContext.Provider>
    );
  }
  return {
    ConfigProvider,
    useConfig,
  };
}
