import * as React from "react";
import styled from "styled-components/macro";
import * as Translation from "../../mf/utils/i18n";
import { AppPage, Crumb, CrumbPad } from "./AppPage";
import { ReportDto } from "../../shared/dtos";
import { CheckList } from "./CheckList";

const Frame = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  display: flex;
  color: ${(props) => props.theme.grey};
  @media print {
    height: auto;
    overflow-y: revert;
  }
`;

const Panel = styled.div`
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex: 0 0 0;
  overflow: auto;
  flex-direction: column;
  padding: 1rem;
  @media print {
    display: none;
  }
`;
const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: #dedede;
  border-left: 1px ${(props) => props.theme.greyLight} solid;
  border-right: 1px ${(props) => props.theme.greyLight} solid;
  position: relative;
  @media print {
    padding: 0;
    border-left: none;
    border-right: none;
    height: auto;
    overflow-y: revert;
  }
`;
interface Props {
  readonly report: ReportDto,
  readonly testid: string,
  readonly toolbarEnd?: React.ReactNode,
  readonly children: React.ReactNode,
  readonly comments: React.ReactNode,
}

export function AppReportPage({ report, testid, toolbarEnd, comments, children }: Props): React.ReactElement {
  const { t } = Translation.useTranslation();

  return (
    <AppPage
      title={report.name}
      testid={testid}
      toolbar={(
        <>
          <Crumb to="/reports">{t("app:PRODUCT_NAME")}</Crumb>
          <CrumbPad />
          <Crumb to={`/reports/${report.id}`}>{report.name}</Crumb>
          {toolbarEnd && <CrumbPad />}
          {toolbarEnd}
        </>
      )}
    >
      <Frame>
        <Panel>
          <CheckList report={report} />
        </Panel>
        <Body>{children}</Body>
        <Panel>{comments}</Panel>
      </Frame>
    </AppPage>
  );
}
