import * as React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import { FormikErrors } from "formik";
import { TFunction, Trans } from "react-i18next";
import { Warning } from "@styled-icons/material-outlined";
import { Check, NoData, ReportStatus } from "../../shared/dtos";
import { reportStyle } from "../report/reportStyle";
import * as Translation from "../../mf/utils/i18n";
import { CheckForm } from "./CheckForm";

const Block = styled.div`
  background-color: white;
  padding: 1rem;
  margin: 0 6px 6px;

  max-width: 100%;
  position: relative;

  &:first-child, h3:first-child {
    margin-top: 6px;
  }

  & .center {
    text-align: center;
  }

  p:last-child {
    margin-bottom: 6px;
  }
`;

const Quote = styled.span`
  display: block;
  margin: 0.3rem 0;
  padding-left: 1rem;
  border-left: 2px solid #cacaca;
  font-style: italic;
`;
const ReportSection = styled.div`
  ${reportStyle}
`;
const Status = styled.div`
  text-align: center;
  padding: 2rem;
`;

const CheckStatus = styled("span")<{ status: ReportStatus }>`
  &::after {
    margin-left: 6px;
    ${({ status }) => status === ReportStatus.SUCCESS && `
      content: '✔';
      color: limegreen;
    `}
    ${({ status }) => (status === ReportStatus.FAILURE || status === ReportStatus.ERROR) && `
      content: '✘';
      color: red;
    `}
  }
`;

export interface ReportSectionProps<Tin, TOut = NoData, TFinal = NoData> {
  readonly data: Check<Tin, TOut, TFinal>,
  readonly showFinal?: boolean,
  readonly submitFinal?: (values: TFinal) => Promise<void>,
}

interface Props<Tin, TOut = NoData, TFinal = NoData> {
  readonly defaults: Tin,
  readonly step: Check<Tin, TOut, TFinal>,
  readonly runStep: (request: Tin) => Promise<void>,
  readonly submitFinal?: (values: TFinal) => Promise<void>,
  readonly validate: (request: Tin, t: (key: string, bag?: { [key: string]: string | number }) => string) => FormikErrors<Tin>,
  readonly checkPurposeKey: string,
  readonly checkBackgroundKey: string,
  readonly checkBackgroundLinkKey?: string,
  readonly formComponent: React.ComponentType<Record<string, never>>,
  readonly resultComponent: React.ComponentType<ReportSectionProps<Tin, TOut, TFinal>>,
}

export function CheckLayout<Tin, TOut, TFinal>({ step, checkBackgroundKey, checkBackgroundLinkKey, checkPurposeKey, runStep, submitFinal, resultComponent, formComponent, defaults, validate }: Props<Tin, TOut, TFinal>): React.ReactElement {
  const { t } = Translation.useTranslation();
  const tf = t as TFunction;

  const ResultsComponent = resultComponent;
  const FormComponent = formComponent;

  return (
    <>
      <Block>
        <Grid container spacing={2}>
          <Grid item lg>
            <h3>{t("app:CHECK_PAGE_PURPOSE")}</h3>
            <Trans i18nKey={checkPurposeKey} t={tf} />
          </Grid>
          <Grid item lg>
            <h3>{t("app:CHECK_PAGE_BACKGROUND")}</h3>
            <Trans
              i18nKey={checkBackgroundKey}
              t={tf}
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                Link: <a href={checkBackgroundLinkKey ? t(checkBackgroundLinkKey) : "#"} target="_blank" rel="noreferrer" />,
                Quote: <Quote />,
              }}
            />
          </Grid>
        </Grid>
      </Block>
      <Block>
        <h3 className="center">{t("app:CHECK_PAGE_STATUS")}</h3>
        <p className="center">
          <CheckStatus status={step.status}>
            {(step.status === ReportStatus.PENDING || step.status === ReportStatus.PENDING_FINAL) && <>{t("app:CHECK_STATUS_PENDING")}</>}
            {step.status === ReportStatus.RUNNING && <>{t("app:CHECK_STATUS_RUNNNING")}</>}
            {step.status === ReportStatus.FAILURE && <>{t("app:CHECK_STATUS_FAILURE")}</>}
            {step.status === ReportStatus.ERROR && <>{t("app:CHECK_STATUS_ERROR")}</>}
            {step.status === ReportStatus.SUCCESS && <>{t("app:CHECK_STATUS_SUCCESS")}</>}
          </CheckStatus>
        </p>
      </Block>
      <Block>
        {step.status === ReportStatus.PENDING && (
          <CheckForm initialValues={defaults} validateAndTranslate={validate} onSubmit={runStep}>
            <FormComponent />
          </CheckForm>
        )}
        {step.status === ReportStatus.ERROR && (
          <Status><Typography color="error"><Warning size={24} /> {step.error!}</Typography></Status>
        )}
        {step.status === ReportStatus.RUNNING && (
          <Status><CircularProgress size={80} /></Status>
        )}
        {(step.status === ReportStatus.SUCCESS || step.status === ReportStatus.FAILURE || step.status === ReportStatus.PENDING_FINAL) && (
          <>
            <h3 className="center">{t("app:CHECK_PAGE_RESULTS")}</h3>
            <ReportSection>
              <ResultsComponent data={step} submitFinal={submitFinal} showFinal={step.status === ReportStatus.PENDING_FINAL} />
            </ReportSection>
          </>
        )}
      </Block>
    </>
  );
}
