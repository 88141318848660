import { css } from "styled-components/macro";

export const reportStyle = css`
  a {
    text-decoration: none;
    color: inherit;
  }
  h1, h2, h3 {
    text-align: center;
  }
  h1 {
    font-size: 2.25rem;
    padding-top: 20px;
    margin-bottom: 0;

    @media print {
      margin-top: 0;
    }
  }
  h2 {
    margin-top: 8px;
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  h5 {
    margin-top: 0.6rem;
    margin-bottom: 0.3rem;
  }
  h6 {
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
  }
  #summary {
    text-align: center;
    max-width: 1200px;
    padding-left: 100px;
    padding-right: 100px;
    margin: auto;
    padding-bottom: 20px;
    font-style: italic;
    &::marker {
      display: none;
    }
  }
  p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  section {
    page-break-inside: avoid;
    padding-bottom: 1em;
    border-bottom: 1px ${(props) => props.theme.greyLight} solid;
    &:last-child {
      padding-bottom:0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
`;
