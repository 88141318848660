import * as React from "react";
import styled from "styled-components/macro";
import { Apps, MenuBook, FactCheck, LiveHelp } from "@styled-icons/material-outlined";
import * as Config from "../../config";
import { NavigationFrame } from "../../mf/style/components/nav/NavFrame";
import { NavigationHeader } from "../../mf/style/components/nav/NavigationHeader";
import { NavigationLink } from "../../mf/style/components/nav/NavigationLink";
import * as Translation from "../../mf/utils/i18n";

const NavigationTop = styled.div`
  flex: 1 1 0;
`;

const NavigationBottom = styled.div`
  flex: 0 0 auto;
`;

interface Props {
  readonly initiallyCollapsedState?: boolean,
}
export function Nav({ initiallyCollapsedState }: Props): React.ReactElement {
  const { t } = Translation.useTranslation();
  const { version } = Config.useConfig();
  return (
    <NavigationFrame companyName={t("app:COMPANY_NAME")} companyNameShort={t("app:COMPANY_NAME_SHORT")} version={version} initiallyCollapsedState={initiallyCollapsedState}>
      <NavigationTop>
        <NavigationHeader text={t("app:PRODUCT_NAME")} />
        <NavigationLink key="reports" to="/reports" icon={<FactCheck />} text={t("app:REPORTS_PAGE")} />
        <NavigationLink key="help" to="/help" icon={<MenuBook />} text={t("app:HELP_PAGE")} />
      </NavigationTop>
      <NavigationBottom>

        <NavigationHeader text={t("app:COMPANY_NAME")} />
        <NavigationLink key="apps" href="https://sandbox.home.mindfoundry.ai" icon={<Apps />} text={t("app:BACK_LINK")} />
        <NavigationLink key="contact" href="https://sandbox.home.mindfoundry.ai/contact" icon={<LiveHelp />} text={t("app:CONTACT_LINK")} />
      </NavigationBottom>
    </NavigationFrame>
  );
}
