import * as React from "react";
import { useParams } from "react-router-dom";
import { ReportId } from "../../../shared/dtos";
import { useReport } from "../../report/useReport";
import { AsyncPage } from "../../app/AsyncPage";
import { DataPrivacyCheckPage } from "./DataPrivacyCheckPage";

export function DataPrivacyCheckPageContainer(): React.ReactElement {
  const { reportId } = useParams<{ reportId: ReportId }>();
  const { report, addComment, runDataPrivacyStep } = useReport(reportId);
  return (
    <AsyncPage
      waitFor={report}
      component={DataPrivacyCheckPage}
      light
      addComment={addComment}
      runDataPrivacyStep={runDataPrivacyStep}
    />
  );
}
