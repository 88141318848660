import * as React from "react";
import { useHistory } from "react-router-dom";
import { ReportId } from "../../shared/dtos";
import { ReportsPage } from "./ReportsPage";
import { useReports } from "./useReports";
import { AsyncPage } from "../app/AsyncPage";

export function ReportsPageContainer(): React.ReactElement {
  const history = useHistory();
  const onSelectReport = React.useCallback((id: ReportId) => {
    history.push(`/reports/${id}/`);
  }, [history]);
  const { reports, createReport, deleteReport } = useReports();
  return (
    <AsyncPage
      waitFor={reports}
      component={ReportsPage}
      light
      onSelectReport={onSelectReport}
      onCreate={createReport}
      onDelete={deleteReport}
    />
  );
}
