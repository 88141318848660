import * as React from "react";
import { Button } from "@material-ui/core";
import * as Translation from "../../mf/utils/i18n";
import { AuthFailureProps } from "../../mf/utils/auth";
import { ErrorPage } from "../app/ErrorPage";

export function AuthErrorPage({ error, reauthenticate }: AuthFailureProps): React.ReactElement {
  const { t } = Translation.useTranslation();
  return (
    <ErrorPage testid="auth-error-page">
      <h2>{t("app:AUTH_ERROR_HEADER")}</h2>
      <p>{error}</p>
      <br />
      <Button color="secondary" variant="outlined" onClick={reauthenticate} data-testid="auth-error-retry">{t("app:AUTH_ERROR_RESET_BUTTON")}</Button>
    </ErrorPage>
  );
}
