import * as React from "react";
import { MouseEvent, useState } from "react";
import { DataGrid, GridCellParams, GridCellValue, GridColDef, GridRowParams, GridValueFormatterParams } from "@material-ui/data-grid";
import styled from "styled-components/macro";
import { Fab, IconButton } from "@material-ui/core";
import { Add, Delete } from "@styled-icons/material-outlined";
import * as Translation from "../../mf/utils/i18n";
import { AppPage, Crumb, CrumbPad } from "../app/AppPage";
import { ReportId, CreateReportRequest, List, ReportStatus, ReportMetaDto } from "../../shared/dtos";
import { CreateReportDialog } from "./CreateReportDialog";
import { AsyncProps } from "../types";
import { DeleteReportDialog } from "./DeleteReportDialog";

const Frame = styled.div`
  flex-grow: 1;
  border: 6px #dedede solid;
  position: relative;
`;

const DeleteButton = styled(IconButton)`
  color: ${(props) => props.theme.danger};
  &:hover {
    color: ${(props) => props.theme.dangerLight};
  }
`;
const Table = styled(DataGrid)`
  [role="row"] {
    cursor: pointer;
  }
  .MuiDataGrid-columnHeader:nth-child(4) > .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }
`;

const ActionButton = styled(Fab)`
  position: absolute;
  bottom: 52px;
  right: ${(props) => props.theme.pad / 2}px;
`;

interface Props extends AsyncProps<List<ReportMetaDto>> {
  readonly onSelectReport: (id: ReportId) => void,
  readonly onCreate: (values: CreateReportRequest) => Promise<void>,
  readonly onDelete: (id: ReportId) => Promise<void>,
}

export function ReportsPage({ value: reports, onSelectReport, onCreate, onDelete }: Props): React.ReactElement {
  const { t } = Translation.useTranslation();

  // Create Dialog
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const openCreateDialog = React.useCallback(() => setCreateDialogOpen(true), [setCreateDialogOpen]);
  const closeCreateDialog = React.useCallback(() => setCreateDialogOpen(false), [setCreateDialogOpen]);

  // Delete Dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<ReportId | undefined>(undefined);
  const openDeleteDialog = React.useCallback((id: ReportId) => setDeleteDialogOpen(id), [setDeleteDialogOpen]);
  const closeDeleteDialog = React.useCallback(() => setDeleteDialogOpen(undefined), [setDeleteDialogOpen]);

  // Rows
  const onRowClick = React.useCallback(({ id }: GridRowParams) => onSelectReport(id as unknown as ReportId), [onSelectReport]);
  const onDeleteClick = React.useCallback((e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const id = e.currentTarget.getAttribute("data-report-id");
    openDeleteDialog(id as unknown as ReportId);
  }, [openDeleteDialog]);
  const columns: ReadonlyArray<GridColDef> = React.useMemo(() => {
    const statusMap: { [status in ReportStatus]: string } = {
      [ReportStatus.PENDING]: t("app:REPORT_STATUS_PENDING"),
      [ReportStatus.PENDING_FINAL]: t("app:REPORT_STATUS_PENDING"),
      [ReportStatus.RUNNING]: t("app:REPORT_STATUS_PENDING"),
      [ReportStatus.SUCCESS]: t("app:REPORT_STATUS_SUCCESS"),
      [ReportStatus.FAILURE]: t("app:REPORT_STATUS_FAILURE"),
      [ReportStatus.ERROR]: t("app:REPORT_STATUS_FAILURE"),
    };
    const formatStatus = ({ value }: GridValueFormatterParams): GridCellValue => statusMap[value as unknown as ReportStatus];
    const renderDelete = ({ value }: GridCellParams): React.ReactElement => (<DeleteButton size="small" onClick={onDeleteClick} data-report-id={value}><Delete size={24} /></DeleteButton>);
    return [
      { field: "name", headerName: t("app:REPORTS_TABLE_NAME_HEADER"), flex: 5 },
      { field: "owner", headerName: t("app:REPORTS_TABLE_OWNER_HEADER"), flex: 3 },
      { field: "status", headerName: t("app:REPORTS_TABLE_STATUS_HEADER"), flex: 2, valueFormatter: formatStatus },
      { field: "id", headerName: " ", width: 62, sortable: false, filterable: false, renderCell: renderDelete },
    ];
  }, [t, onDeleteClick]);

  return (
    <AppPage
      title={t("app:REPORTS_PAGE")}
      testid="reports-page"
      toolbar={(
        <>
          <Crumb to="/reports">{t("app:PRODUCT_NAME")}</Crumb>
          <CrumbPad />
          <Crumb to="/reports">{t("app:REPORTS_PAGE")}</Crumb>
        </>
      )}
    >
      <Frame>
        <Table columns={columns as Array<GridColDef>} rows={reports.list} pagination autoPageSize onRowClick={onRowClick} />
        <ActionButton color="primary" onClick={openCreateDialog} title={t("app:CREATE_REPORT_DIALOG_TITLE")}><Add /></ActionButton>
        {createDialogOpen && <CreateReportDialog closeDialog={closeCreateDialog} onCreate={onCreate} />}
        {deleteDialogOpen !== undefined && <DeleteReportDialog closeDialog={closeDeleteDialog} onDelete={onDelete} id={deleteDialogOpen} />}
      </Frame>
    </AppPage>
  );
}
