// Dtos
export type ReportId = string;
export type Timestamp = string;

export interface CSV {
  readonly name: string | undefined,
  readonly body: ReadonlyArray<Record<string, string>> | undefined,
  readonly columns: ReadonlyArray<string> | undefined,
  readonly pending: boolean,
}

export enum ReportStatus {
  PENDING = "PENDING",
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  RUNNING = "RUNNING",
  PENDING_FINAL = "PENDING_FINAL",
}

export interface List<T> {
  // No top level arrays - http://haacked.com/archive/2009/06/25/json-hijacking.aspx/
  readonly list: ReadonlyArray<T>,
}

export type Step = "systemPurpose" | "dataTransparency" | "dataPrivacy" | "systemTransparency" | "systemEquality";

export interface Comment {
  readonly message: string,
  readonly avatar: string,
  readonly name: string,
  readonly timestamp: Timestamp,
}

export type NoData = Record<string, never>;

export interface Check<TIn, TOut = NoData, TFinal = NoData> {
  readonly status: ReportStatus,
  readonly comments: ReadonlyArray<Comment>,
  readonly in?: TIn,
  readonly out?: TOut,
  readonly final?: TFinal,
  readonly error?: string,
}

type ColumnName = string;
type ColumnValue = string;
export type PerColumn<T> = Record<ColumnName, T>;

export interface SystemPurposeData {
  readonly purpose: string,
  readonly owner: string,
  readonly notes: string,
}

export type ColumnUse = "feature" | "label";
export interface DataTransparencyData {
  readonly dataset: CSV,
  readonly dataContent: string,
  readonly dataQuality: string,
  readonly dataUse: string,
  readonly dataOwner: string,
  readonly columnContent: PerColumn<string>,
  readonly columnQuality: PerColumn<string>,
  readonly columnOffensive: PerColumn<boolean>,
  readonly columnUse: PerColumn<ColumnUse>,
  readonly notes: string,
}

export type IdType = "direct" | "quasi" | "na";
export type PrivacyMitigation = "none" | "pseudo" | "rounding" | "truncation" | "categorisation" | "noise" | "kanonymity" | "differential" | "other";
export interface DataPrivacyData {
  readonly dataset: CSV,
  readonly idType: PerColumn<IdType>,
  readonly mitigation: PerColumn<PrivacyMitigation>,
  readonly mitigationComments: PerColumn<string>,
  readonly notes: string,
}

export interface SystemTransparencyData {
  readonly details: string,
  readonly balancedInterests: string,
  readonly feedbackLoops: string,
  readonly systemOwner: string,
  readonly technicalDescription: string,
  readonly metrics: string,
  readonly ethical: string,
  readonly modelOwner: string,
  readonly decisionExplanation: string,
  readonly humanIntervention: string,
  readonly modelLogic: string,
  readonly notes: string,
}

export type FairnessMetric = "equal-opportunity" | "demographic-parity" | "average-fairness";
export interface SystemEqualityData {
  readonly dataset: CSV,
  readonly fairnessMetric: FairnessMetric,
  readonly labelColumn?: ColumnName,
  readonly predictionColumn: ColumnName,
  readonly privilegedLabel?: ColumnValue,
  readonly protectedAttribute: PerColumn<boolean>,
}

export interface ReportMetaDto {
  readonly id: ReportId,
  readonly name: string,
  readonly owner: string,
  readonly status: ReportStatus,
}

export type GroupValue = string | number;
export type PerGroup<T> = Record<GroupValue, T>;

export interface SystemEqualityGroupResult {
  readonly isFair: boolean,
  readonly metric: number,
}
export interface SystemEqualityColumnResult {
  readonly groups: PerGroup<SystemEqualityGroupResult>,
}
export interface SystemEqualityResult {
  readonly averageMetric: number,
  readonly columns: PerColumn<SystemEqualityColumnResult>,
}

export interface SystemEqualityFinal {
  readonly justifications: PerColumn<PerGroup<string>>,
  readonly overrideFair: PerColumn<PerGroup<boolean>>,
}

export interface ReportDto extends ReportMetaDto {
  readonly id: ReportId,
  readonly name: string,
  readonly owner: string,
  readonly status: ReportStatus,
  readonly summaryNotes?: string,
  readonly comments: ReadonlyArray<Comment>,
  readonly systemPurpose: Check<SystemPurposeData>,
  readonly dataTransparency: Check<DataTransparencyData>,
  readonly dataPrivacy: Check<DataPrivacyData>,
  readonly systemTransparency: Check<SystemTransparencyData>,
  readonly systemEquality: Check<SystemEqualityData, SystemEqualityResult, SystemEqualityFinal>,
}

export interface CreateReportRequest {
  readonly name: string,
}

export interface AddCommentRequest {
  readonly message: string,
  readonly avatar: string,
  readonly step?: Step,
}

export interface SummaryNotesRequest {
  readonly message: string,
}
