import { CreateReportRequest, List, ReportDto, ReportId, ReportMetaDto } from "../../shared/dtos";
import { extract, headers } from "../app/fetchUtils";

export async function fetchReports(getAccessToken: () => Promise<string>): Promise<List<ReportMetaDto>> {
  const token = await getAccessToken();
  const response = await fetch("/api/reports", {
    method: "GET",
    headers: headers(token),
  });
  return extract<List<ReportDto>>(response);
}
export async function putReport(request: CreateReportRequest, getAccessToken: () => Promise<string>): Promise<ReportDto> {
  const token = await getAccessToken();
  const response = await fetch("/api/reports", {
    method: "POST",
    headers: headers(token),
    body: JSON.stringify(request),
  });
  return extract<ReportDto>(response);
}
export async function deleteReport(id: ReportId, getAccessToken: () => Promise<string>): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/api/reports/${id}`, {
    method: "DELETE",
    headers: headers(token),
  });
}
