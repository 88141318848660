import * as React from "react";
import { Handyman } from "@styled-icons/material-outlined";
import { PageFrame } from "../../mf/style/components/page/PageFrame";
import * as Translation from "../../mf/utils/i18n";

export function NotFoundPage(): React.ReactElement {
  const { t } = Translation.useTranslation();
  return (
    <PageFrame testid="not-found-page" mode="center">
      <Handyman size={160} />
      <br />
      <h2>{t("app:404_HEADER")}</h2>
      <p>{t("app:404_HELP")}</p>
    </PageFrame>
  );
}
