import * as React from "react";
import styled from "styled-components/macro";
import { Portal } from "react-portal";

const Positioner = styled.div`
  position: relative;
  float: right;
  height: 0;
  width: 0;
  overflow: visible;
  z-index: 1000;
`;

// outer box
const Menu = styled.div`
  border: 1px solid ${(props) => props.theme.greyLight};
  margin-top: ${(props) => props.theme.dropdownPad}px;
  min-width: 140px;
  background-color: ${(props) => props.theme.grey};
  padding: 0;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.4);
  border-radius: 3px;
  width: fit-content;
  position: absolute;
  right: 0;
`;

// catches click
const Wrapper = styled.div`
  position: relative;
`;

// catches click out
const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
`;

interface Props {
  readonly target: React.ReactNode,
  readonly children: React.ReactNode,
  readonly className?: string,
  readonly testid?: string,
  readonly initiallyOpenState?: boolean,
}

export function DropDown({ target, children, className, testid, initiallyOpenState }: Props): React.ReactElement {
  const [open, setOpen] = React.useState<boolean>(initiallyOpenState ?? false);
  const toggleOpen = React.useCallback(() => setOpen(!open), [setOpen, open]);
  return (
    <Wrapper onClick={toggleOpen} className={className} data-testid={testid}>
      {target}
      {open && (
        <>
          <Portal>
            <Backdrop data-testid="menu-bg" onClick={toggleOpen} />
          </Portal>
          <Positioner>
            <Menu data-testid="menu-open">
              {children}
            </Menu>
          </Positioner>
        </>
      )}
    </Wrapper>
  );
}
