import * as React from "react";
import { useTheme } from "styled-components/macro";
import { createMuiTheme, StylesProvider, ThemeProvider } from "@material-ui/core";
import { enUS } from "@material-ui/core/locale";
import { useTranslation } from "../../mf/utils/i18n";

interface Props {
  children: React.ReactNode,
}
export function MuiProvider({ children }: Props): React.ReactElement {
  const { language } = useTranslation();
  const locale = React.useMemo(() => {
    if (language === "some example") {
      // return a mui locale
    }
    return enUS;
  }, [language]);
  const scTheme = useTheme();
  const theme = React.useMemo(() => createMuiTheme({
    spacing: scTheme.pad,
    palette: {
      primary: {
        main: scTheme.blueLight,
      },
      secondary: {
        main: scTheme.white,
      },
    },
    // Synchronise more of theme here as needed
  }, locale), [scTheme, locale]);
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
}
