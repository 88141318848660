import * as React from "react";
import { useDropzone } from "react-dropzone";
import { CircularProgress, FormControl as MuiFormControl, FormHelperText, InputLabel, OutlinedInput, Typography } from "@material-ui/core";
import { Warning } from "@styled-icons/material-outlined";
import { useField, useFormikContext } from "formik";
import { CSV } from "../../shared/dtos";
import { useTranslation } from "../../mf/utils/i18n";
import { FormControl } from "./FormControl";
import { parseCSV } from "./parse";

// `workerize-loader` causes webpack to code split the main app bundle, to create a separate bundle (entry point app/parse) to run on the worker thread.
// `inline` tells webpack to store the 2nd bundle as a blob in the first (to avoid a 2nd network fetch).
// The imported value is a wrapped version of the app/parse module (that erases the types :( ).
// When called a worker thread is created and proxy of the app/parse module is returned.
// The proxy can used to make promisified calls to the exported functions in the app/parse module.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS2307
// eslint-disable-next-line import/no-unresolved, import/order, import/no-webpack-loader-syntax
import parseWorker from "workerize-loader?inline!./parse";

const typedParseWorker = parseWorker as (() => { terminate: () => void, parseCSV: (...params: Parameters<typeof parseCSV>) => Promise<ReturnType<typeof parseCSV>> });

interface Props {
  readonly name: string,
  readonly label: string,
  readonly placeholder: string,
  readonly explanation?: React.ReactNode,
  readonly onChangeDependantFieldUpdates?: (columns: ReadonlyArray<string>) => Record<string, unknown>,
}

export function CsvInput({
  name,
  label,
  placeholder,
  explanation,
  onChangeDependantFieldUpdates,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [{ value }, { touched, error }] = useField<CSV>(name);
  const { setValues, setFieldTouched, values } = useFormikContext<Record<string, unknown>>();

  const setValue = React.useCallback((val: CSV) => {
    // When a data set arrives, set the default values for the column based fields
    setValues({
      ...values,
      ...(onChangeDependantFieldUpdates?.(val.columns ?? []) ?? {}),
      [name]: val,
    });
    // values intentionally omitted - dont want the effect to fire for every value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues, onChangeDependantFieldUpdates, name]);

  const onDropAccepted = React.useCallback(async (files: ReadonlyArray<File>) => {
    setFieldTouched(name, false);
    const file = files[0];
    setValue({ name: file.name, pending: true, body: undefined, columns: undefined });

    // Parsing the file here is a very hokey approach that wont scale, but works for poc
    const worker = typedParseWorker();
    try {
      const parsed = await worker.parseCSV(file);
      setValue(parsed);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setValue({ name: file.name, pending: false, body: undefined, columns: undefined });
    } finally {
      worker.terminate();
      setFieldTouched(name);
    }
  }, [setValue, setFieldTouched, name]);

  const onDropRejected = React.useCallback(() => {
    setValue({ name: undefined, body: undefined, pending: false, columns: undefined });
  }, [setValue]);

  const dz = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: ".csv,text/csv",
    maxSize: 1e+7,
    maxFiles: 1,
    multiple: false,
  });

  const active = dz.isDragActive || dz.isFileDialogActive || value.name !== undefined || false;

  const inner = (
    <MuiFormControl variant="outlined" error={touched && Boolean(error)} fullWidth focused={active}>
      <InputLabel htmlFor={name} shrink={active}>
        {label}
      </InputLabel>
      <OutlinedInput
        notched={active}
        id={name}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputComponent="div"
        label={label}
        fullWidth
        inputProps={{
          ...dz.getRootProps(),
          children: (
            <>
              <input {...dz.getInputProps()} />
              {!dz.isDragActive && value.name !== undefined && <span>{value.name}{value.pending && <>&nbsp;<CircularProgress size={14} /></>}</span>}
              {dz.isDragActive && dz.isDragAccept && <span><em>{placeholder}</em></span>}
              {dz.isDragActive && dz.isDragReject && <Typography color="error"><Warning size={18} />&nbsp;{t("app:FIELD_VALIDATION_NOT_CSV")}</Typography>}
            </>
          ),
        }}
      />
      {touched && error && (
        <FormHelperText>
          {error}
        </FormHelperText>
      )}
    </MuiFormControl>
  );

  return !explanation ? inner : (
    <FormControl explanation={explanation}>
      {inner}
    </FormControl>
  );
}
