import * as React from "react";
import { ColumnUse, DataTransparencyData, ReportStatus } from "../../../shared/dtos";
import { useTranslation } from "../../../mf/utils/i18n";
import { ReportSectionProps } from "../../app/CheckLayout";
import { ColumnStats } from "../../app/ColumnStats";

const columnUseNames: { [val in ColumnUse]: string } = {
  feature: "app:DATA_TRANSPARENCY_FIELD_COLUMN_USE_OPTION_FEATURE",
  label: "app:DATA_TRANSPARENCY_FIELD_COLUMN_USE_OPTION_LABEL",
};

export function DataTransparencyReportSection({ data, showFinal }: ReportSectionProps<DataTransparencyData>): React.ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t("app:REPORT_TEXT_SUBSECTION_CONFIGURATION")}</h4>
      {data.status === ReportStatus.PENDING || data.status === ReportStatus.RUNNING || data.status === ReportStatus.ERROR || (data.status === ReportStatus.PENDING_FINAL && !showFinal) ? (
        <p><em>{t("app:REPORT_TEXT_CHECK_INCOMPLETE")}</em></p>
      ) : (
        <>
          <p>
            {t("app:DATA_TRANSPARENCY_FIELD_DATASET_LABEL")}: {data.in!.dataset.name || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:DATA_TRANSPARENCY_FIELD_DATA_CONTENT_LABEL")}: {data.in!.dataContent || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:DATA_TRANSPARENCY_FIELD_DATA_QUALITY_LABEL")}: {data.in!.dataQuality || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:DATA_TRANSPARENCY_FIELD_DATA_USE_LABEL")}: {data.in!.dataUse || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:DATA_TRANSPARENCY_FIELD_DATA_OWNER_LABEL")}: {data.in!.dataOwner || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:DATA_TRANSPARENCY_FIELD_NOTES_LABEL")}: {data.in!.notes || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {data.in!.dataset.columns!.map((column) => (
              <>
                <ColumnStats name={column} data={data.in!.dataset.body!} left />
                {t("app:DATA_TRANSPARENCY_FIELD_COLUMN_CONTENT_LABEL")}: {data.in!.columnContent[column] || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
                {t("app:DATA_TRANSPARENCY_FIELD_COLUMN_QUALITY_LABEL")}: {data.in!.columnQuality[column] || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
                {t("app:DATA_TRANSPARENCY_FIELD_COLUMN_OFFENSIVE_LABEL")} {data.in!.columnOffensive[column] ? t("app:TRUE_LABEL") : t("app:FALSE_LABEL")}<br />
                {t("app:DATA_TRANSPARENCY_FIELD_COLUMN_USE_LABEL")}: {t(columnUseNames[data.in!.columnUse[column]] || "app:OPTIONAL_FIELD_FALLBACK")}<br />
              </>
            ))}
          </p>
        </>
      )}
    </>
  );
}
