import * as React from "react";
import { SystemTransparencyData, ReportDto, AddCommentRequest } from "../../../shared/dtos";
import { AsyncProps } from "../../types";
import { AppReportPage } from "../../app/AppReportPage";
import { DocumentTitle } from "../../../mf/utils/title";
import { Crumb } from "../../app/AppPage";
import { Comments } from "../../app/Comments";
import { useTranslation } from "../../../mf/utils/i18n";
import { CheckLayout } from "../../app/CheckLayout";
import { SystemTransparencyReportSection } from "./SystemTransparencyReportSection";
import { defaults, SystemTransparencyForm } from "./SystemTransparencyForm";
import { validateSystemTransparencyData } from "../../../shared/validation";

interface Props extends AsyncProps<ReportDto> {
  readonly addComment: (message: AddCommentRequest) => Promise<void>,
  readonly runSystemTransparencyStep: (request: SystemTransparencyData) => Promise<void>,
}
export function SystemTransparencyCheckPage({ value: report, addComment, runSystemTransparencyStep }: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <AppReportPage
      report={report}
      testid="system-transparency-check-page"
      toolbarEnd={
        <Crumb to={`/reports/${report.id}/system-transparency`}>{t("app:SYSTEM_TRANSPARENCY_CHECK_NAME")}</Crumb>
      }
      comments={
        <Comments comments={report.systemTransparency.comments} addComment={addComment} step="systemTransparency" />
      }
    >
      <DocumentTitle title={t("app:SYSTEM_TRANSPARENCY_CHECK_NAME")}>
        <CheckLayout
          step={report.systemTransparency}
          runStep={runSystemTransparencyStep}
          resultComponent={SystemTransparencyReportSection}
          formComponent={SystemTransparencyForm}
          checkBackgroundKey="app:SYSTEM_TRANSPARENCY_BACKGROUND"
          checkBackgroundLinkKey="app:SYSTEM_TRANSPARENCY_BACKGROUND_LINK"
          checkPurposeKey="app:SYSTEM_TRANSPARENCY_DESCRIPTION"
          validate={validateSystemTransparencyData}
          defaults={defaults}
        />
      </DocumentTitle>
    </AppReportPage>
  );
}
