import * as React from "react";
import { AddCommentRequest, ReportDto, SystemPurposeData } from "../../../shared/dtos";
import { AsyncProps } from "../../types";
import { AppReportPage } from "../../app/AppReportPage";
import { DocumentTitle } from "../../../mf/utils/title";
import { Crumb } from "../../app/AppPage";
import { Comments } from "../../app/Comments";
import { useTranslation } from "../../../mf/utils/i18n";
import { SystemPurposeReportSection } from "./SystemPurposeReportSection";
import { CheckLayout } from "../../app/CheckLayout";
import { defaults, SystemPurposeForm } from "./SystemPurposeForm";
import { validateSystemPurposeData } from "../../../shared/validation";

interface Props extends AsyncProps<ReportDto> {
  readonly addComment: (message: AddCommentRequest) => Promise<void>,
  readonly runSystemPurposeStep: (request: SystemPurposeData) => Promise<void>,
}

export function SystemPurposeCheckPage({ value: report, addComment, runSystemPurposeStep }: Props): React.ReactElement {
  const { t } = useTranslation();

  return (
    <AppReportPage
      report={report}
      testid="system-purpose-check-page"
      toolbarEnd={
        <Crumb to={`/reports/${report.id}/system-purpose`}>{t("app:SYSTEM_PURPOSE_CHECK_NAME")}</Crumb>
      }
      comments={
        <Comments comments={report.systemPurpose.comments} addComment={addComment} step="systemPurpose" />
      }
    >
      <DocumentTitle title={t("app:SYSTEM_PURPOSE_CHECK_NAME")}>
        <CheckLayout
          step={report.systemPurpose}
          runStep={runSystemPurposeStep}
          resultComponent={SystemPurposeReportSection}
          formComponent={SystemPurposeForm}
          checkBackgroundKey="app:SYSTEM_PURPOSE_BACKGROUND"
          checkBackgroundLinkKey="app:SYSTEM_PURPOSE_BACKGROUND_LINK"
          checkPurposeKey="app:SYSTEM_PURPOSE_DESCRIPTION"
          validate={validateSystemPurposeData}
          defaults={defaults}
        />
      </DocumentTitle>
    </AppReportPage>
  );
}
