import styled from "styled-components/macro";
import * as React from "react";
import { VictoryArea, VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryTheme } from "victory";
import { DomainPropType, VictoryLabel, VictoryStyleInterface } from "victory-core";
import { CircularProgress } from "@material-ui/core";
import { CategoricalBin, ContinuousBin, HistogramInsight, histogramInsights } from "./histogram";
import { useTranslation } from "../../mf/utils/i18n";

// `workerize-loader` causes webpack to code split the main app bundle, to create a separate bundle (entry point app/histogram) to run on the worker thread.
// `inline` tells webpack to store the 2nd bundle as a blob in the first (to avoid a 2nd network fetch).
// The imported value is a wrapped version of the app/histogram module (that erases the types :( ).
// When called a worker thread is created and proxy of the app/histogram module is returned.
// The proxy can used to make promisified calls to the exported functions in the app/parse module.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS2307
// eslint-disable-next-line import/no-unresolved, import/order, import/no-webpack-loader-syntax
import histogramWorker from "workerize-loader?inline!./histogram";

const typedHistogramWorker = histogramWorker as (() => { terminate: () => void, histogramInsights: (...params: Parameters<typeof histogramInsights>) => Promise<ReturnType<typeof histogramInsights>> });

const Frame = styled.div<{ left?: boolean }>`
  display: flex;
  position: relative;
  max-width: 100%;
  flex-direction: column;
  margin-top:  ${(props) => (props.left ? 0.5 : 0)}rem;
  align-items: ${(props) => (props.left ? "unset" : "center")};
  text-align: ${(props) => (props.left ? "left" : "center")};
  margin-bottom: 2px;
  page-break-inside: avoid;
  & > h5 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  & > div {
    text-align: left;
    border: 1px solid ${(props) => props.theme.greyLight};
    margin-bottom: ${(props) => (props.left ? 0 : 10)}px;
    padding: 3px;
    max-width: 100%;
    min-width: 450px;
    height: 206px;
    width: min(600px, 100%);
    position: relative;
  }
`;
const Progress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SideBySide = styled.div`
  padding: 0;
  display: flex;
  height: 200px;
  align-items: center;
  & > div {
    &:first-child {
      flex-grow: 3;
    }
    &:last-child {
      flex-grow: 1;
    }
    flex-shrink: 0;
    flex-basis: 0;
    overflow: hidden;
  }
  & p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

function formatShort(n: string | number): string {
  // Do something smart later
  const long = formatLong(n);
  return long.length > 9 ? `${long.substr(0, 8)}...` : long;
}

function formatLong(n: string | number): string {
  // Do something smart later (number formatting)
  return typeof n === "string" ? n : n.toString();
}

const InsightRow = styled.p`
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0 !important;
  }
  font-size: 0.8rem;
`;
interface InsightProps {
  label: string,
  value: number | string,
}
export function Insight({ label, value }: InsightProps): React.ReactElement {
  const long = React.useMemo(() => formatLong(value), [value]);
  return (<InsightRow><strong>{label}:</strong>&nbsp;<span title={long}>{long}</span></InsightRow>);
}

const Top = styled.div`
  margin: 10px 50px 10px 20px;
  position: relative;
  font-size: 0.8rem;
  & > :first-child {
    display: block;
  }
`;

const barStyle: VictoryStyleInterface = { data: { fill: "#0092D2" } };
const lineStyle = { data: { stroke: "white" } };
const axisStyle = { grid: { stroke: "none" } };
const padding = { top: 15, left: 45, right: 25, bottom: 50 };

interface CategoricalHistogramProps {
  readonly bins: ReadonlyArray<CategoricalBin>,
}
function CategoricalHistogram({ bins }: CategoricalHistogramProps): React.ReactElement {
  const data = React.useMemo(() => bins.map(({ value, count }) => ({ x: formatShort(value), y: count })), [bins]);
  const domain = React.useMemo<DomainPropType>(() => ({ x: [0.5, data.length + 0.5], y: [0, data.reduce((agg, val) => (agg > val.y ? agg : val.y), 0) * 1.05] }), [data]);
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domain={domain}
      padding={padding}
      width={500}
      height={220}
    >
      <VictoryBar
        data={data}
        style={barStyle}
        barRatio={0.95}
      />
      <VictoryAxis
        tickCount={data.length}
        style={axisStyle}
        tickLabelComponent={<VictoryLabel angle={-25} textAnchor="end" verticalAnchor="middle" />}
      />
      <VictoryAxis
        dependentAxis
        style={axisStyle}
        axisValue={0.5}
      />
    </VictoryChart>
  );
}

interface ContinousHistogramProps {
  readonly bins: ReadonlyArray<ContinuousBin>,
}

function ContinuousHistogram({ bins }: ContinousHistogramProps): React.ReactElement {
  const data = React.useMemo(() => [{ x: bins[0].min, y: bins[0].count }, ...bins.map(({ max, count }) => ({ x: max, y: count }))], [bins]);
  const yMax = React.useMemo(() => bins.reduce((agg, { count }) => (agg > count ? agg : count), 0) * 1.05, [bins]);
  const lines = React.useMemo(() => bins.flatMap((bin) => [{ y: 0, x: bin.max }, { y: yMax, x: bin.max }, { y: 0, x: bin.max }]), [bins, yMax]);
  const domain = React.useMemo<DomainPropType>(() => ({ x: [bins[0].min, bins[bins.length - 1].max], y: [0, yMax] }), [bins, yMax]);
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domain={domain}
      padding={padding}
      width={500}
      height={220}
    >
      <VictoryArea
        data={data}
        style={barStyle}
        interpolation="stepBefore"
      />
      <VictoryLine
        data={lines}
        style={lineStyle}
      />
      <VictoryAxis
        style={axisStyle}
        tickLabelComponent={<VictoryLabel angle={-25} textAnchor="end" verticalAnchor="middle" />}
      />
      <VictoryAxis
        dependentAxis
        style={axisStyle}
        axisValue={bins[0].min}
      />
    </VictoryChart>
  );
}

interface Props {
  readonly name: string,
  // eslint-disable-next-line react/no-unused-prop-types
  readonly data: ReadonlyArray<Record<string, string>>,
  readonly left?: boolean,
}
export function ColumnStats({ name, left, data }: Props): React.ReactElement {
  const { t } = useTranslation();
  const [insights, setInsights] = React.useState<HistogramInsight | undefined>(undefined);
  React.useEffect(() => {
    const worker = typedHistogramWorker();
    worker.histogramInsights(name, data)
      .then((res) => setInsights(res))
      .finally(() => worker.terminate());
    return () => {
      worker.terminate();
    };
  }, [name, data, setInsights]);

  return (
    <Frame left={left}>
      <h5>{name}</h5>
      <div>
        {insights ? (
          <SideBySide>
            <div>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {insights.bins?.[0].value !== undefined && (
              <CategoricalHistogram bins={insights.bins as ReadonlyArray<CategoricalBin>} />
              )}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {insights.bins && insights.bins[0].value === undefined && (
              <ContinuousHistogram bins={insights.bins as ReadonlyArray<ContinuousBin>} />
              )}
              {!insights.bins && (
                <Top>
                  <strong>Top {insights.head!.length}:</strong>
                  {insights.head!.map(({ value }, i) => (
                    <Insight key={value} label={(i + 1).toString()} value={value} />
                  ))}
                </Top>
              )}
            </div>
            <div>
              <Insight key="type" label={t("app:COLUMN_INSIGHT_TYPE")} value={insights.numbers ? t("app:COLUMN_INSIGHT_TYPE_NUMBER") : t("app:COLUMN_INSIGHT_TYPE_TEXT")} />
              <Insight key="unique" label={t("app:COLUMN_INSIGHT_UNIQUE")} value={insights.unique} />
              <Insight key="missing" label={t("app:COLUMN_INSIGHT_MISSING")} value={insights.missing} />
              {insights.min !== undefined && <Insight key="min" label={t("app:COLUMN_INSIGHT_MIN")} value={insights.min} />}
              {insights.max !== undefined && <Insight key="max" label={t("app:COLUMN_INSIGHT_MAX")} value={insights.max} />}
              {insights.mean !== undefined && <Insight key="mean" label={t("app:COLUMN_INSIGHT_MEAN")} value={insights.mean} />}
              {insights.mode !== undefined && <Insight key="mode" label={t("app:COLUMN_INSIGHT_MODE")} value={insights.mode} />}
              {insights.antiMode !== undefined && <Insight key="antimode" label={t("app:COLUMN_INSIGHT_ANTIMODE")} value={insights.antiMode} />}
            </div>
          </SideBySide>
        ) : (
          <Progress>
            <CircularProgress size={50} />
          </Progress>
        )}
      </div>
    </Frame>
  );
}
