import * as React from "react";
import { AddCommentRequest, DataTransparencyData, ReportDto } from "../../../shared/dtos";
import { AsyncProps } from "../../types";
import { AppReportPage } from "../../app/AppReportPage";
import { DocumentTitle } from "../../../mf/utils/title";
import { Crumb } from "../../app/AppPage";
import { Comments } from "../../app/Comments";
import { useTranslation } from "../../../mf/utils/i18n";
import { CheckLayout } from "../../app/CheckLayout";
import { DataTransparencyReportSection } from "./DataTransparencyReportSection";
import { DataTransparencyForm, defaults } from "./DataTransparencyForm";
import { validateDataTransparencyData } from "../../../shared/validation";

interface Props extends AsyncProps<ReportDto> {
  readonly addComment: (message: AddCommentRequest) => Promise<void>,
  readonly runDataTransparencyStep: (request: DataTransparencyData) => Promise<void>,
}
export function DataTransparencyCheckPage({ value: report, addComment, runDataTransparencyStep }: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <AppReportPage
      report={report}
      testid="data-transparency-check-page"
      toolbarEnd={
        <Crumb to={`/reports/${report.id}/data-transparency`}>{t("app:DATA_TRANSPARENCY_CHECK_NAME")}</Crumb>
      }
      comments={
        <Comments comments={report.dataTransparency.comments} addComment={addComment} step="dataTransparency" />
      }
    >
      <DocumentTitle title={t("app:DATA_TRANSPARENCY_CHECK_NAME")}>
        <CheckLayout
          step={report.dataTransparency}
          runStep={runDataTransparencyStep}
          resultComponent={DataTransparencyReportSection}
          formComponent={DataTransparencyForm}
          checkBackgroundKey="app:DATA_TRANSPARENCY_BACKGROUND"
          checkPurposeKey="app:DATA_TRANSPARENCY_DESCRIPTION"
          validate={validateDataTransparencyData}
          defaults={defaults}
        />
      </DocumentTitle>
    </AppReportPage>
  );
}
