/* eslint-disable jsx-a11y/heading-has-content */
import * as React from "react";
import { TFunction, Trans } from "react-i18next";
import styled from "styled-components/macro";
import * as Translation from "../../mf/utils/i18n";
import { AppPage, Crumb, CrumbPad } from "../app/AppPage";
import { reportStyle } from "../report/reportStyle";
import image1 from "./image1.png";
import image2 from "./image2.png";

const Quote = styled.span`
  display: block;
  margin: 0.3rem 0;
  padding-left: 1rem;
  border-left: 2px solid #cacaca;
  font-style: italic;
`;

const OuterFrame = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  overflow: auto;
  display: flex;
  color: ${(props) => props.theme.grey};
  background: #dedede;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  position: relative;
`;

const Frame = styled.div`
  padding: 1rem;
  width: 100%;
  position: relative;
  @media print {
    padding: 0;
  }
`;
const Paper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border: 1px ${(props) => props.theme.greyLight} solid;
  width: 100%;
  max-width: 1400px;
  border-radius: 5px;
  background-color: white;
  padding: 2rem 4rem;
  @media print {
    padding: 0;
    width: 100%;
    border: none;
  }
  ${reportStyle}
  h3 {
    margin-top: 1.2rem;
    margin-bottom: 0.8rem;
    text-align: left;
  }
  h4 {
    margin-top: 1rem;
    margin-bottom: 0.65rem;
    font-size: 1.2rem;
  }
  h5 {
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }
  h6 {
    margin-top: 0.6rem;
    margin-bottom: 0.4rem;
    font-size: 1rem;
  }
  text-align: justify;
  img {
    max-width: calc(100% - 8rem);
    height: auto;
    width: auto;
  }
`;

const components = {
  quote: <Quote />,
  h1: <h1 />,
  h2: <h2 />,
  h3: <h3 />,
  h4: <h4 />,
  h5: <h5 />,
  h6: <h6 />,
  ul: <ul />,
  ol: <ol />,
  li: <li />,
  b: <b />,
  strong: <strong />,
  i: <i />,
  em: <em />,
  p: <p />,
  br: <br />,
  // eslint-disable-next-line jsx-a11y/alt-text
  img1: <img src={image1} />,
  // eslint-disable-next-line jsx-a11y/alt-text
  img2: <img src={image2} />,
};

export function HelpPage(): React.ReactElement {
  const { t } = Translation.useTranslation();

  const tf = t as TFunction;
  return (
    <AppPage
      title={t("app:HELP_PAGE")}
      testid="help-page"
      toolbar={(
        <>
          <Crumb to="/reports">{t("app:PRODUCT_NAME")}</Crumb>
          <CrumbPad />
          <Crumb to="/help">{t("app:HELP_PAGE")}</Crumb>
        </>
     )}
    >
      <OuterFrame>
        <Frame>
          <Paper>
            <Trans
              i18nKey="app:HELP_TEXT"
              t={tf}
              components={components}
            />
          </Paper>
        </Frame>
      </OuterFrame>
    </AppPage>
  );
}
