import * as React from "react";
import { TFunction, Trans } from "react-i18next";
import * as Translation from "../../../mf/utils/i18n";
import { TextInput } from "../../app/TextInput";
import { SystemPurposeData } from "../../../shared/dtos";

export const defaults: SystemPurposeData = {
  purpose: "",
  owner: "",
  notes: "",
};

export function SystemPurposeForm(): React.ReactElement {
  const { t } = Translation.useTranslation();
  const tf = t as TFunction;

  return (
    <>
      <TextInput
        key="purpose"
        name="purpose"
        label={t("app:SYSTEM_PURPOSE_FIELD_PURPOSE_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_PURPOSE_FIELD_PURPOSE_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_PURPOSE_FIELD_PURPOSE_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="owner"
        name="owner"
        label={t("app:SYSTEM_PURPOSE_FIELD_OWNER_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_PURPOSE_FIELD_OWNER_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_PURPOSE_FIELD_OWNER_PLACEHOLDER")}
        size={3}
      />
      <TextInput
        key="notes"
        name="notes"
        label={t("app:SYSTEM_PURPOSE_FIELD_NOTES_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_PURPOSE_FIELD_NOTES_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_PURPOSE_FIELD_NOTES_PLACEHOLDER")}
        size={6}
      />
    </>
  );
}
