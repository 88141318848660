import { BasicConfig, configure } from "./mf/utils/config";
import meta from "./messages/meta.json";
import { formatters } from "./messages/formatters";

export type Config = BasicConfig;

// See index.html
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - we add this to window ourselves in the index.html
const runtimeConfig = (window.config) as (Partial<Config> | undefined);

// runtime-prod || compiletime-prod/runtime-dev || fallback
export const config: Config = {
  version: runtimeConfig?.version || process.env.REACT_APP_VERSION || "D.E.V",
  auth: {
    clientId: runtimeConfig?.auth?.clientId || process.env.REACT_APP_AUTH_CLIENT_ID || "missing",
    domain: runtimeConfig?.auth?.domain || process.env.REACT_APP_AUTH_DOMAIN || "missing",
    audience: runtimeConfig?.auth?.audience || process.env.REACT_APP_AUTH_AUDIENCE || "missing",
  },
  support: {
    account: runtimeConfig?.support?.account || process.env.REACT_APP_ACCOUNT || null,
    accountName: runtimeConfig?.support?.accountName || process.env.REACT_APP_ACCOUNT_NAME || null,
    analytics: {
      enableAnalytics: runtimeConfig?.support?.analytics?.enableAnalytics || !!process.env.REACT_APP_ANALYTICS || false,
      mixpanelToken: runtimeConfig?.support?.analytics?.mixpanelToken || process.env.REACT_APP_ANALYTICS_MIXPANEL_TOKEN || null,
    },
  },
  i18n: {
    languageDebug: runtimeConfig?.i18n?.languageDebug || process.env.NODE_ENV === "development",
    approvedLanguages: ["en"],
    groups: ["app"],
    translations: meta,
    formatters,
  },
};

const { ConfigProvider, useConfig } = configure<Config>(config);
export { ConfigProvider };
export { useConfig };

// This export should only be directly referenced in tests!
export const testConfig: Config = config;
