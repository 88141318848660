import * as React from "react";
import { ConfigProvider } from "./config";
import { ContextProvider } from "./mf/utils/context";
import { defaultTheme, GlobalStyle } from "./mf/style/theme";
import { DefaultLoadingPage } from "./app/app/LoadingPage";
import { AuthErrorPage } from "./app/auth/AuthErrorPage";
import { MuiProvider } from "./app/app/MuiProvider";

export function Context({ mock, children }: { readonly mock?: boolean, readonly children?: React.ReactNode }): React.ReactElement {
  return (
    <ContextProvider
      configProvider={ConfigProvider}
      globalStyles={GlobalStyle}
      defaultTheme={defaultTheme}
      loadingComponent={DefaultLoadingPage}
      authFailureComponent={AuthErrorPage}
      companyNameKey="app:COMPANY_NAME_SHORT"
      appNameKey="app:PRODUCT_NAME"
      mock={mock}
    >
      <MuiProvider>
        {children}
      </MuiProvider>
    </ContextProvider>
  );
}
