import * as React from "react";
import { SystemEqualityData, ReportDto, AddCommentRequest, SystemEqualityFinal } from "../../../shared/dtos";
import { AsyncProps } from "../../types";
import { AppReportPage } from "../../app/AppReportPage";
import { DocumentTitle } from "../../../mf/utils/title";
import { Crumb } from "../../app/AppPage";
import { Comments } from "../../app/Comments";
import { useTranslation } from "../../../mf/utils/i18n";
import { CheckLayout } from "../../app/CheckLayout";
import { SystemEqualityReportSection } from "./SystemEqualityReportSection";
import { defaults, SystemEqualityForm } from "./SystemEqualityForm";
import { validateSystemEqualityData } from "../../../shared/validation";

interface Props extends AsyncProps<ReportDto> {
  readonly addComment: (message: AddCommentRequest) => Promise<void>,
  readonly runSystemEqualityStep: (request: SystemEqualityData) => Promise<void>,
  readonly submitSystemEqualityFinal: (request: SystemEqualityFinal) => Promise<void>,
}
export function SystemEqualityCheckPage({ value: report, addComment, runSystemEqualityStep, submitSystemEqualityFinal }: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <AppReportPage
      report={report}
      testid="system-equality-check-page"
      toolbarEnd={
        <Crumb to={`/reports/${report.id}/system-equality`}>{t("app:SYSTEM_EQUALITY_CHECK_NAME")}</Crumb>
      }
      comments={
        <Comments comments={report.systemEquality.comments} addComment={addComment} step="systemEquality" />
      }
    >
      <DocumentTitle title={t("app:SYSTEM_EQUALITY_CHECK_NAME")}>
        <CheckLayout
          step={report.systemEquality}
          runStep={runSystemEqualityStep}
          submitFinal={submitSystemEqualityFinal}
          resultComponent={SystemEqualityReportSection}
          formComponent={SystemEqualityForm}
          checkBackgroundKey="app:SYSTEM_EQUALITY_BACKGROUND"
          checkPurposeKey="app:SYSTEM_EQUALITY_DESCRIPTION"
          validate={validateSystemEqualityData}
          defaults={defaults}
        />
      </DocumentTitle>
    </AppReportPage>
  );
}
