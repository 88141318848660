import * as React from "react";
import styled from "styled-components/macro";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import * as Translation from "../../mf/utils/i18n";
import { DocumentTitle } from "../../mf/utils/title";
import { Page } from "../page/Page";
import { Logo } from "../../mf/style/components/util/Logo";

const HomeLogo = styled(Logo)`
  height: 160px;
  & .text {
    fill: ${(props) => (props.theme.white)};
  }
  & .icon {
    fill: ${(props) => props.theme.white};
  }
`;

export function HomePage(): React.ReactElement {
  const { t } = Translation.useTranslation();

  return (
    <DocumentTitle title={t("app:HOME_PAGE")}>
      <Page testid="home-page" mode="center">
        <HomeLogo />
        <h1>{t("app:PRODUCT_NAME")}</h1>
        <br />
        <br />
        <br />
        <div><Button component={Link} color="secondary" variant="outlined" to="/reports">{t("app:START_TEXT")}</Button></div>
      </Page>
    </DocumentTitle>
  );
}
