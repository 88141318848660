import * as React from "react";
import styled from "styled-components/macro";
import { Print } from "@styled-icons/material-outlined";
import { Fab } from "@material-ui/core";
import { AddCommentRequest, ReportDto, SummaryNotesRequest } from "../../shared/dtos";
import { AsyncProps } from "../types";
import { AppReportPage } from "../app/AppReportPage";
import { DocumentTitle } from "../../mf/utils/title";
import { Comments } from "../app/Comments";
import { Report } from "./Report";
import { useTranslation } from "../../mf/utils/i18n";

const ActionButton = styled(Fab)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  @media print {
    display: none;
  }
`;

interface Props extends AsyncProps<ReportDto> {
  readonly addComment: (message: AddCommentRequest) => Promise<void>,
  readonly submitSummaryNotes: (values: SummaryNotesRequest) => Promise<void>,
}

export function ReportPage({ value: report, addComment, submitSummaryNotes }: Props): React.ReactElement {
  const { t } = useTranslation();
  const onPrint = React.useCallback(() => {
    window.print();
  }, []);
  return (
    <AppReportPage
      report={report}
      testid="report-page"
      comments={
        <Comments comments={report.comments} addComment={addComment} />
      }
    >
      <DocumentTitle title={t("app:REPORT_PAGE")}>
        <Report report={report} submitSummaryNotes={submitSummaryNotes} />
        <ActionButton color="primary" onClick={onPrint}><Print size={35} /></ActionButton>
      </DocumentTitle>
    </AppReportPage>
  );
}
