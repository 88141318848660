import React from "react";

import { useLocation } from "react-router-dom";
import { AnalyticsContext, AnalyticsProxy } from "./analytics";
import { useAuth } from "./auth";

export const useAnalytics = (): AnalyticsProxy => React.useContext(AnalyticsContext)!;

export const usePageViewAnalytics = (): void => {
  const { key } = useLocation();
  const { user } = useAuth();
  const analytics = useAnalytics();
  React.useEffect(() => {
    if (user) {
      analytics.page();
    }
  }, [analytics, key, user]);
};

export const useLoginAnalytics = (): void => {
  const { user } = useAuth();
  const analytics = useAnalytics();
  React.useEffect(() => {
    if (user) {
      analytics.identify();
    }
  }, [analytics, user]);
};
