import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../mf/utils/auth";
import { CreateReportRequest, List, ReportId, ReportMetaDto } from "../../shared/dtos";
import { Async } from "../types";
import { fetchReports, putReport, deleteReport } from "./reportsApi";

interface Result {
  readonly reports: Async<List<ReportMetaDto>>,
  readonly createReport: (request: CreateReportRequest) => Promise<void>,
  readonly deleteReport: (id: ReportId) => Promise<void>,
}
export function useReports(): Result {
  const { getAccessToken } = useAuth();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { push } = useHistory();
  const [reports, setReports] = useState<Async<List<ReportMetaDto>>>({ loading: true });

  const getReports = useCallback(() => fetchReports(getAccessToken)
    .then((value) => setReports({ value }))
    .catch((error: Error) => setReports({ error })), [getAccessToken]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getReports();
  }, [getReports]);

  const createReport = useCallback(async (request: CreateReportRequest): Promise<void> => {
    const { id } = await putReport(request, getAccessToken);
    push(`/reports/${id}`);
  }, [push, getAccessToken]);

  const deleteReportAction = useCallback(async (id: ReportId): Promise<void> => {
    await deleteReport(id, getAccessToken);
    await getReports();
  }, [getAccessToken, getReports]);

  return {
    reports,
    createReport,
    deleteReport: deleteReportAction,
  };
}
