import * as React from "react";
import { useParams } from "react-router-dom";
import { ReportPage } from "./ReportPage";
import { useReport } from "./useReport";
import { ReportId } from "../../shared/dtos";
import { AsyncPage } from "../app/AsyncPage";

export function ReportPageContainer(): React.ReactElement {
  const { reportId } = useParams<{ reportId: ReportId }>();
  const { report, addComment, submitSummaryNotes } = useReport(reportId);
  return (
    <AsyncPage
      waitFor={report}
      component={ReportPage}
      light
      addComment={addComment}
      submitSummaryNotes={submitSummaryNotes}
    />
  );
}
