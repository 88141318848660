import { css, createGlobalStyle, DefaultTheme } from "styled-components/macro";
import { normalize } from "styled-normalize";

declare module "styled-components" {
  // eslint-disable-next-line no-shadow
  export interface DefaultTheme {
    readonly name: string,
    readonly white: string,
    readonly grey: string,
    readonly greyLight: string,
    readonly blue: string,
    readonly blueLight: string,
    readonly blueDark: string,
    readonly danger: string,
    readonly dangerLight: string,
    readonly backgroundSize: string,
    readonly navExpandedSize: number,
    readonly navCollapsedSize: number,
    readonly navToggleSize: number,
    readonly linkFontSize: number,
    readonly linkHeight: number,
    readonly pad: number,
    readonly dropdownPad: number,
  }
}

export const defaultTheme: DefaultTheme = {
  name: "default",
  white: "#ffffff",
  grey: "#262F3B",
  greyLight: "#92979d",
  blue: "#00457C",
  blueLight: "#0092D2",
  blueDark: "#00305D",
  danger: "#c00000",
  dangerLight: "#ff2828",
  backgroundSize: "cover",
  navExpandedSize: 275,
  navCollapsedSize: 85,
  navToggleSize: 10,
  linkFontSize: 1.5,
  linkHeight: 3,
  pad: 20,
  dropdownPad: 7,
};

export const iconStyle = css`
  & circle, & path {
    stroke: currentColor;
    stroke-width: 1.8%;
  }
  & text {
    fill: currentColor;
    font-family: inherit;
    font-weight: bold;
    font-size: 24px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  *, *::before, *::after {
    box-sizing: border-box;
  }
  html, body, #root {
    font-family: 'Lato', sans-serif;
    height: 100%;
    min-width: 1200px;
    min-height: 600px;
    font-size: 16px;
    @media print {
      min-width: unset;
      height: auto;
      font-size: 12px;
    }
  }
  body {
    background-color: ${(props) => props.theme.blueDark};
    color: ${(props) => props.theme.white};
    display: flex;
  }
  #root {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    flex: auto 1 1;
    position: relative;
    @media print {
      display: block;
    }
  }

  option {
    padding: 3px 5px;
  }
  option:hover {
    background-color: #eaeaea !important;
  }
`;
