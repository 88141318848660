import * as React from "react";
import { FormikErrors } from "formik";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import { Warning } from "@styled-icons/material-outlined";
import { CreateReportRequest } from "../../shared/dtos";
import * as Translation from "../../mf/utils/i18n";
import { useForm } from "../app/useForm";
import { validateCreateReportRequest } from "../../shared/validation";
import { useAnalytics } from "../../mf/utils/analyticsHooks";

const DialogProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0 5px 0;
`;

const DialogWarning = styled(Button)`
  margin-right: auto;
  svg {
    display: inline-flex;
  }
`;

interface Props {
  readonly closeDialog: () => void,
  readonly onCreate: (values: CreateReportRequest) => Promise<void>,
}
export function CreateReportDialog({ onCreate, closeDialog }: Props): React.ReactElement {
  const { t } = Translation.useTranslation();
  const analytics = useAnalytics();

  const onSubmit = React.useCallback(async (values: CreateReportRequest) => {
    await onCreate(values);
    analytics.track("report", "created", { nameLength: values.name.length });
    closeDialog();
  }, [closeDialog, onCreate, analytics]);

  const initialValues = React.useMemo<CreateReportRequest>(() => ({ name: "" }), []);

  const validate = React.useCallback((values: CreateReportRequest): FormikErrors<CreateReportRequest> => validateCreateReportRequest(values, t), [t]);

  const {
    handleSubmit,
    handleReset,
    handleChange,
    isValid,
    isSubmitting,
    values,
    touched,
    errors,
    submitError,
  } = useForm<CreateReportRequest>({ initialValues, validate, onSubmit });

  return (
    <Dialog open onClose={closeDialog} fullWidth>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <DialogTitle id="simple-dialog-title">{t("app:CREATE_REPORT_DIALOG_TITLE")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("app:CREATE_REPORT_DIALOG_DESCRIPTION")}
          </DialogContentText>
          {isSubmitting ? (
            <DialogProgress>
              <CircularProgress />
            </DialogProgress>
          ) : (
            <TextField
              id="name"
              name="name"
              label={t("app:CREATE_REPORT_DIALOG_NAME_LABEL")}
              fullWidth
              onChange={handleChange}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              placeholder={t("app:CREATE_REPORT_DIALOG_NAME_PLACEHOLDER")}
              autoFocus
              margin="dense"
            />
          )}
        </DialogContent>
        <DialogActions>
          {submitError && <DialogWarning disabled><Typography color="error"><Warning size={18} /> {submitError.message}</Typography></DialogWarning>}
          <Button onClick={closeDialog} color="default" disabled={isSubmitting}>
            {t("app:CREATE_REPORT_DIALOG_CANCEL_ACTION")}
          </Button>
          <Button type="submit" color="primary" disabled={isSubmitting || !isValid}>
            {t("app:CREATE_REPORT_DIALOG_CREATE_ACTION")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
