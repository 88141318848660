import * as React from "react";
import { AddCommentRequest, DataPrivacyData, ReportDto } from "../../../shared/dtos";
import { AsyncProps } from "../../types";
import { AppReportPage } from "../../app/AppReportPage";
import { DocumentTitle } from "../../../mf/utils/title";
import { Crumb } from "../../app/AppPage";
import { Comments } from "../../app/Comments";
import { useTranslation } from "../../../mf/utils/i18n";
import { CheckLayout } from "../../app/CheckLayout";
import { DataPrivacyReportSection } from "./DataPrivacyReportSection";
import { DataPrivacyForm, defaults } from "./DataPrivacyForm";
import { validateDataPrivacyData } from "../../../shared/validation";

interface Props extends AsyncProps<ReportDto> {
  readonly addComment: (message: AddCommentRequest) => Promise<void>,
  readonly runDataPrivacyStep: (request: DataPrivacyData) => Promise<void>,
}
export function DataPrivacyCheckPage({ value: report, addComment, runDataPrivacyStep }: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <AppReportPage
      report={report}
      testid="data-privacy-check-page"
      toolbarEnd={
        <Crumb to={`/reports/${report.id}/data-privacy`}>{t("app:DATA_PRIVACY_CHECK_NAME")}</Crumb>
      }
      comments={
        <Comments comments={report.dataPrivacy.comments} addComment={addComment} step="dataPrivacy" />
      }
    >
      <DocumentTitle title={t("app:DATA_PRIVACY_CHECK_NAME")}>
        <CheckLayout
          step={report.dataPrivacy}
          runStep={runDataPrivacyStep}
          resultComponent={DataPrivacyReportSection}
          formComponent={DataPrivacyForm}
          checkBackgroundKey="app:DATA_PRIVACY_BACKGROUND"
          checkPurposeKey="app:DATA_PRIVACY_DESCRIPTION"
          validate={validateDataPrivacyData}
          defaults={defaults}
        />
      </DocumentTitle>
    </AppReportPage>
  );
}
