import * as React from "react";
import { useParams } from "react-router-dom";
import { ReportId } from "../../../shared/dtos";
import { useReport } from "../../report/useReport";
import { AsyncPage } from "../../app/AsyncPage";
import { SystemPurposeCheckPage } from "./SystemPurposeCheckPage";

export function SystemPurposeCheckPageContainer(): React.ReactElement {
  const { reportId } = useParams<{ reportId: ReportId }>();
  const { report, addComment, runSystemPurposeStep } = useReport(reportId);
  return (
    <AsyncPage
      waitFor={report}
      component={SystemPurposeCheckPage}
      light
      addComment={addComment}
      runSystemPurposeStep={runSystemPurposeStep}
    />
  );
}
