/* eslint-disable no-nested-ternary */
import styled from "styled-components/macro";
import * as React from "react";

const Item = styled.div<{ selected?: boolean, disabled?: boolean, danger?: boolean }>`
  text-decoration: ${(props) => (props.disabled ? "italic" : "none")};
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  background-color: transparent;
  padding: ${(props) => props.theme.dropdownPad}px;
  color: ${(props) => (props.danger ? props.theme.danger : (props.selected ? props.theme.white : props.theme.greyLight))};
  font-size: 14px;
  &:hover {
    background-color: ${(props) => (props.disabled ? "transparent" : "rgba(255, 255, 255, 0.2)")};
    color: ${(props) => (props.disabled ? "unset" : props.theme.white)};
  }
`;

interface Props {
  readonly onClick?: () => void,
  readonly selected?: boolean,
  readonly disabled?: boolean,
  readonly danger?: boolean,
  readonly children?: React.ReactNode,
  readonly className?: string,
  readonly testid?: string,
}

export function DropDownItem({ onClick, selected, disabled, danger, children, className, testid }: Props): React.ReactElement {
  return (<Item onClick={onClick} selected={selected} disabled={disabled} danger={danger} className={className} data-testid={testid}>{children}</Item>);
}
