import * as React from "react";
import styled from "styled-components/macro";

const Header = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.blueLight}FF;
  font-weight: bold;
  font-size: 0.75rem;
  margin: ${(props) => props.theme.pad}px;
  transition: all ease 1s;
  border-top: 1px solid ${(props) => props.theme.greyLight}00;
  overflow: hidden;
  width: 235px;
  height: 18px;
  .collapsed & {
    margin: 0 auto 20px;
    height: ${(props) => props.theme.pad / 2}px;
    width: ${(props) => props.theme.pad / 2}px;
    background-color: ${(props) => props.theme.blueLight}FF;
    color: ${(props) => props.theme.blueLight}00;
  }
  .collapsed &:not(:first-child) {
    margin-top: ${(props) => props.theme.pad / 2}px;
    margin-bottom: ${(props) => props.theme.pad / 2}px;
    border-top: 1px solid  ${(props) => props.theme.greyLight}FF;
  }
`;

interface Props {
  readonly text: string,
  readonly className?: string,
  readonly testid?: string,
}

export function NavigationHeader({ text, className, testid }: Props): React.ReactElement {
  return (<Header className={className} data-testid={testid} title={text}>{text}</Header>);
}
