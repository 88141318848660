import * as React from "react";
import { useParams } from "react-router-dom";
import { ReportId } from "../../../shared/dtos";
import { useReport } from "../../report/useReport";
import { AsyncPage } from "../../app/AsyncPage";
import { SystemEqualityCheckPage } from "./SystemEqualityCheckPage";

export function SystemEqualityCheckPageContainer(): React.ReactElement {
  const { reportId } = useParams<{ reportId: ReportId }>();
  const { report, addComment, runSystemEqualityStep, submitSystemEqualityFinal } = useReport(reportId);
  return (
    <AsyncPage
      waitFor={report}
      component={SystemEqualityCheckPage}
      light
      addComment={addComment}
      runSystemEqualityStep={runSystemEqualityStep}
      submitSystemEqualityFinal={submitSystemEqualityFinal}
    />
  );
}
