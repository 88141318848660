import React from "react";
import styled from "styled-components/macro";

const ColumnBlock = styled.div<{ first: boolean }>`
  border-bottom: 1px ${(props) => props.theme.greyLight} solid;
  margin-bottom: 18px;
  ${(props) => props.first && `
    border-top: 1px ${props.theme.greyLight} solid;
    padding-top: 18px;
  `}
`;
interface Props {
  readonly first: boolean,
  readonly children: React.ReactNode,
}
export function FormColumnBlock({ first, children }: Props): React.ReactElement {
  return (
    <ColumnBlock first={first}>{children}</ColumnBlock>
  );
}
