import * as React from "react";
import { Checkbox, FormControl as MuiFormControl, FormControlLabel, FormHelperText } from "@material-ui/core";
import { useField } from "formik";
import { FormControl } from "./FormControl";

interface Props {
  readonly name: string,
  readonly label: string,
  readonly explanation?: React.ReactNode,
}

export function BooleanInput({
  name,
  label,
  explanation,
}: Props): React.ReactElement {
  const [{ value, onChange }, { touched, error }] = useField<boolean>(name);

  const inner = (
    <MuiFormControl variant="outlined" error={touched && Boolean(error)} fullWidth>
      <FormControlLabel
        control={(
          <Checkbox
            id={name}
            checked={value}
            onChange={onChange}
            name={name}
            color="primary"
          />
        )}
        checked={value ?? false}
        label={label}
      />
      {touched && error && (
        <FormHelperText>
          {error}
        </FormHelperText>
      )}
    </MuiFormControl>
  );

  return !explanation ? inner : (
    <FormControl explanation={explanation}>
      {inner}
    </FormControl>
  );
}
