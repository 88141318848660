import React from "react";
import { Avatar, CircularProgress, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, TextField } from "@material-ui/core";
import styled from "styled-components/macro";
import { PlayArrow } from "@styled-icons/material-outlined";
import { FormikErrors, FormikHelpers } from "formik";
import { AddCommentRequest, Comment, Step } from "../../shared/dtos";
import { useAuth } from "../../mf/utils/auth";
import { useForm } from "./useForm";
import * as Translation from "../../mf/utils/i18n";
import { validateAddCommentRequest } from "../../shared/validation";

const Outer = styled.div`
  border:  1px ${(props) => props.theme.greyLight} solid;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
`;
const Upper = styled.div`
  flex-grow: 1;
`;
const Lower = styled.form`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 5px;
  padding-left: 10px;
  border-top: 1px ${(props) => props.theme.greyLight} solid;
`;
const None = styled.p`
  font-style: italic;
  text-align: center;
  color: ${(props) => props.theme.greyLight};
`;
const SubmitAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
`;

interface Props {
  readonly comments: ReadonlyArray<Comment>,
  readonly addComment: (message: AddCommentRequest) => Promise<void>,
  readonly step?: Step,
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Comments({ comments, addComment, step }: Props): React.ReactElement {
  const { t } = Translation.useTranslation();

  const { user } = useAuth();
  const avatar = user.picture!;

  const validate = React.useCallback((values: AddCommentRequest): FormikErrors<AddCommentRequest> => validateAddCommentRequest(values, t), [t]);
  const initialValues = React.useMemo<AddCommentRequest>(() => ({
    message: "",
    avatar,
    step,
  }), [avatar, step]);

  const onSubmit = React.useCallback(async (values: AddCommentRequest, { resetForm }: FormikHelpers<AddCommentRequest>): Promise<void> => {
    await addComment(values);
    resetForm();
  }, [addComment]);

  const {
    handleSubmit,
    handleReset,
    handleChange,
    values,
    touched,
    errors,
    submitError,
    isSubmitting,
    isValid,
  } = useForm<AddCommentRequest>({ onSubmit, initialValues, validate, enableReinitialize: true });

  return (
    <Outer>
      <Upper>
        {comments.length === 0 ? (
          <None>{t("app:COMMENT_BOX_COMMENTS_NONE")}</None>
        ) : (
          <List>
            {comments.map((comment) => (
              <ListItem alignItems="flex-start" dense>
                <ListItemAvatar>
                  <Avatar src={comment.avatar} alt={comment.name} variant="rounded" />
                </ListItemAvatar>
                <ListItemText primary={comment.name} secondary={comment.message} />
              </ListItem>
            ))}
          </List>
        )}
      </Upper>
      <Lower onSubmit={handleSubmit} onReset={handleReset}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SubmitAvatar src={user.picture} variant="rounded" />
              </InputAdornment>
            ),
          }}
          id="message"
          name="message"
          fullWidth
          onChange={handleChange}
          value={values.message}
          error={Boolean(submitError) || (touched.message && Boolean(errors.message))}
          helperText={submitError?.message || (touched.message && errors.message)}
          disabled={isSubmitting}
          margin="dense"
          placeholder={t("app:COMMENT_FIELD_PLACEHOLDER")}
        />
        <TextField
          hidden
          id="avatar"
          name="avatar"
          onChange={handleChange}
          value={values.avatar}
          error={touched.avatar && Boolean(errors.avatar)}
          helperText={touched.avatar && errors.avatar}
          disabled={isSubmitting}
        />
        <TextField
          hidden
          id="step"
          name="step"
          onChange={handleChange}
          value={values.step}
          error={touched.step && Boolean(errors.step)}
          helperText={touched.step && errors.step}
          disabled={isSubmitting}
        />
        <IconButton size="small" type="submit" disabled={isSubmitting || !isValid}>
          {isSubmitting ? <CircularProgress size={24} /> : <PlayArrow size={24} />}
        </IconButton>
      </Lower>
    </Outer>
  );
}
