import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Nav } from "../nav/Nav";
import { NotFoundPage } from "./NotFoundPage";
import { HomePage } from "../home/HomePage";
import { HelpPage } from "../help/HelpPage";
import { ReportsPageContainer } from "../reports/ReportsPageContainer";
import { ReportPageContainer } from "../report/ReportPageContainer";
import { SystemPurposeCheckPageContainer } from "../checks/system-purpose/SystemPurposeCheckPageContainer";
import { DataTransparencyCheckPageContainer } from "../checks/data-transparency/DataTransparencyCheckPageContainer";
import { DataPrivacyCheckPageContainer } from "../checks/data-privacy/DataPrivacyCheckPageContainer";
import { SystemTransparencyCheckPageContainer } from "../checks/system-transparency/SystemTransparencyCheckPageContainer";
import { SystemEqualityCheckPageContainer } from "../checks/system-equality/SystemEqualityCheckPageContainer";
import { useLoginAnalytics, usePageViewAnalytics } from "../../mf/utils/analyticsHooks";

export function App(): React.ReactElement {
  useLoginAnalytics();
  usePageViewAnalytics();
  return (
    <>
      <Nav initiallyCollapsedState />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/help" component={HelpPage} />
        <Route exact path="/reports" component={ReportsPageContainer} />
        <Route exact path="/reports/:reportId/" component={ReportPageContainer} />
        <Route exact path="/reports/:reportId/system-purpose" component={SystemPurposeCheckPageContainer} />
        <Route exact path="/reports/:reportId/data-transparency" component={DataTransparencyCheckPageContainer} />
        <Route exact path="/reports/:reportId/data-privacy" component={DataPrivacyCheckPageContainer} />
        <Route exact path="/reports/:reportId/system-transparency" component={SystemTransparencyCheckPageContainer} />
        <Route exact path="/reports/:reportId/system-equality" component={SystemEqualityCheckPageContainer} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
}
