import * as React from "react";
import styled from "styled-components/macro";
import { EqualityPlot } from "./EqualityPlot";
import { ReportSectionProps } from "../../app/CheckLayout";
import { SystemEqualityData, SystemEqualityFinal, SystemEqualityResult } from "../../../shared/dtos";
import { useTranslation } from "../../../mf/utils/i18n";
import { BooleanInput } from "../../app/BooleanInput";
import { TextInput } from "../../app/TextInput";

const TextBox = styled(TextInput)`
  max-width: 600px;
  margin-top: 5px;
`;
const Col = styled.div`
  page-break-inside: avoid;
`;
const population = 1000;

export function SystemEqualityReportContent({ data, showFinal }: ReportSectionProps<SystemEqualityData, SystemEqualityResult, SystemEqualityFinal>): React.ReactElement {
  const { t } = useTranslation();
  return (
    <>
      {Object.keys(data.out!.columns).length === 0 && <p>{t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_NO_COLUMNS")}</p>}
      {Object.entries(data.out!.columns)
        .map(([column, { groups }]) => {
          const { averageMetric } = data.out!;
          const unfairGroups = Object.entries(groups)
            .filter(([_, { isFair }]) => !isFair);
          const fairGroups = Object.entries(groups)
            .filter(([_, { isFair }]) => isFair)
            .map(([name]) => name);
          const columnInfo = {
            projection: Math.round(averageMetric * population),
            population,
            averageMetric: averageMetric.toPrecision(4),
            column,
            predictionColumn: data.in!.predictionColumn,
          };
          return (
            <Col>
              <h5>{column}</h5>
              <EqualityPlot averageMetric={averageMetric} groups={groups} fairnessMetric={data.in!.fairnessMetric} columnName={data.in!.predictionColumn} />
              <p>
                {unfairGroups.length === 0 ? (
                  <>
                    {t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_NO_BIAS", columnInfo)}
                    <br />
                    <br />
                  </>
                ) : (
                  <>
                    {t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS", columnInfo)}
                    {data.in?.fairnessMetric === "demographic-parity" && t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_AVERAGE_DEMOGRAPHIC_PARITY", columnInfo)}
                    {data.in?.fairnessMetric === "equal-opportunity" && t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_AVERAGE_EQUAL_OPPORTUNITY", columnInfo)}
                    {data.in?.fairnessMetric === "average-fairness" && t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_AVERAGE_AVERAGE_FAIRNESS", columnInfo)}
                    <br />
                    <br />
                    {t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_GROUPS")}
                    <ul>
                      {unfairGroups.map(([group, { metric }], i) => {
                        const groupInfo = { projection: Math.round(Math.abs(metric - averageMetric) * population), population, metric: metric.toPrecision(4), column, group, predictionColumn: data.in!.predictionColumn };
                        return (
                          <li key={group}>
                            {data.in?.fairnessMetric === "demographic-parity" && metric < averageMetric && t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_GROUP_DEMOGRAPHIC_PARITY_UNDER", groupInfo)}
                            {data.in?.fairnessMetric === "demographic-parity" && metric >= averageMetric && t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_GROUP_DEMOGRAPHIC_PARITY_OVER", groupInfo)}
                            {data.in?.fairnessMetric === "equal-opportunity" && metric < averageMetric && t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_GROUP_EQUAL_OPPORTUNITY_UNDER", groupInfo)}
                            {data.in?.fairnessMetric === "equal-opportunity" && metric >= averageMetric && t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_GROUP_EQUAL_OPPORTUNITY_OVER", groupInfo)}
                            {data.in?.fairnessMetric === "average-fairness" && metric < averageMetric && t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_GROUP_AVERAGE_FAIRNESS_UNDER", groupInfo)}
                            {data.in?.fairnessMetric === "average-fairness" && metric >= averageMetric && t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_BIAS_GROUP_AVERAGE_FAIRNESS_OVER", groupInfo)}
                            <br />
                            {showFinal ? (
                              <TextBox
                                label={t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_JUSTIFICATION_LABEL", groupInfo)}
                                name={`justifications.${column}.${group}`}
                                placeholder={t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_JUSTIFICATION_PLACEHOLDER", groupInfo)}
                                size={6}
                              />
                            ) : (
                              <>{t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_JUSTIFICATION_LABEL", groupInfo)}: {data.final?.justifications?.[column]?.[group] || t("app:OPTIONAL_FIELD_FALLBACK")}</>
                            )}
                            <br />
                            {showFinal ? (
                              <BooleanInput
                                label={t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_OVERRIDE_LABEL", groupInfo)}
                                name={`overrideFair.${column}.${group}`}
                              />
                            ) : (
                              <>{t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_OVERRIDE_LABEL", groupInfo)}: {data.final?.overrideFair?.[column]?.[group] || false ? t("app:TRUE_LABEL") : t("app:FALSE_LABEL")}</>
                            )}
                            <br />
                            {i !== unfairGroups.length - 1 && <br />}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
                {fairGroups.length > 0 && (
                  <>
                    {t("app:SYSTEM_EQUALITY_SUMMARY_REPORT_COLUMN_NO_BIAS_GROUPS")}
                    <ul>
                      {fairGroups.map((group) => (<li>{group}</li>))}
                    </ul>
                  </>
                )}
              </p>
            </Col>
          );
        })}
    </>
  );
}
