import * as React from "react";
import { ErrorOutline } from "@styled-icons/material-outlined";
import { PageFrame } from "../../mf/style/components/page/PageFrame";

interface Props {
  readonly children: React.ReactNode,
  readonly testid?: string,
}

export function ErrorPage({ children, testid }: Props): React.ReactElement {
  return (
    <PageFrame testid={testid ?? "error-page"} mode="center">
      <ErrorOutline size={160} />
      <br />
      {typeof children === "string" && <p>{children}</p>}
      {typeof children !== "string" && children}
    </PageFrame>
  );
}
