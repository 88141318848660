import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { ChevronRight } from "@styled-icons/material-outlined";
import * as React from "react";
import { DocumentTitle } from "../../mf/utils/title";
import { Page } from "../page/Page";

const Toolbar = styled.div`
  flex: 0 0 auto;
  & > div {
    margin: 0.75rem;
    height: 45px;
    max-height: 45px;
    overflow: visible;
    border-right: 1px ${(props) => props.theme.greyLight} solid;
    display: flex;
    align-items: center;
  }
  border-bottom: 1px ${(props) => props.theme.greyLight} solid;
  padding-right: calc(45px + 0.75rem);
  padding-left: 1rem;
  color: ${(props) => props.theme.grey};
  background-color: white;
  width: 100%;
  @media print {
    display: none;
  }
`;

const Body = styled.div`
  flex: 1 1 0;
  background-color: white;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
    flex-basis: auto;
    height: auto;
    overflow-y: revert;
  }
`;
export const Crumb = styled(Link)`
  font-size: 20px;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;
export const CrumbPad = styled(ChevronRight)`
  height: 20px;
  margin: 0 0.5rem;
  text-decoration: none;
  color: ${(props) => props.theme.greyLight};
  &:hover {
    font-weight: bold;
  }
`;

interface Props {
  title: string,
  testid: string,
  toolbar: React.ReactNode,
  children: React.ReactNode,
}
export function AppPage({ title, testid, toolbar, children }: Props): React.ReactElement {
  return (
    <DocumentTitle title={title}>
      <Page testid={testid} mode="seemless">
        <Toolbar>
          <div>
            {toolbar}
          </div>
        </Toolbar>
        <Body>
          {children}
        </Body>
      </Page>
    </DocumentTitle>
  );
}
