import * as React from "react";
import { SystemTransparencyData, ReportStatus } from "../../../shared/dtos";
import { useTranslation } from "../../../mf/utils/i18n";
import { ReportSectionProps } from "../../app/CheckLayout";

export function SystemTransparencyReportSection({ data, showFinal }: ReportSectionProps<SystemTransparencyData>): React.ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t("app:REPORT_TEXT_SUBSECTION_CONFIGURATION")}</h4>
      {data.status === ReportStatus.PENDING || data.status === ReportStatus.RUNNING || data.status === ReportStatus.ERROR || (data.status === ReportStatus.PENDING_FINAL && !showFinal) ? (
        <p><em>{t("app:REPORT_TEXT_CHECK_INCOMPLETE")}</em></p>
      ) : (
        <>
          <p>
            <h5>{t("app:SYSTEM_TRANSPARENCY_SYSTEM_SECTION_HEADER")}</h5>
            {t("app:SYSTEM_TRANSPARENCY_FIELD_DETAILS_LABEL")}: {data.in!.details || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_TRANSPARENCY_FIELD_BALANCED_INTERESTS_LABEL")}: {data.in!.balancedInterests || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_TRANSPARENCY_FIELD_FEEDBACK_LOOPS_LABEL")}: {data.in!.feedbackLoops || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_TRANSPARENCY_FIELD_HUMAN_INTERVENTION_LABEL")}: {data.in!.humanIntervention || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_TRANSPARENCY_FIELD_SYSTEM_OWNER_LABEL")}: {data.in!.systemOwner || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            <h5>{t("app:SYSTEM_TRANSPARENCY_MODEL_SECTION_HEADER")}</h5>
            {t("app:SYSTEM_TRANSPARENCY_FIELD_TECHNICAL_DESCRIPTION_LABEL")}: {data.in!.technicalDescription || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_TRANSPARENCY_FIELD_METRICS_LABEL")}: {data.in!.metrics || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_TRANSPARENCY_FIELD_ETHICAL_LABEL")}: {data.in!.ethical || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_TRANSPARENCY_FIELD_DECISION_EXPLANATION_LABEL")}: {data.in!.decisionExplanation || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_TRANSPARENCY_FIELD_MODEL_LOGIC_LABEL")}: {data.in!.modelLogic || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_TRANSPARENCY_FIELD_MODEL_OWNER_LABEL")}: {data.in!.modelOwner || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            <h5>{t("app:SYSTEM_TRANSPARENCY_OTHER_SECTION_HEADER")}</h5>
            {t("app:SYSTEM_TRANSPARENCY_FIELD_NOTES_LABEL")}: {data.in!.notes || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
          </p>
        </>
      )}
    </>
  );
}
