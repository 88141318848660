import { DefaultTheme, ThemeProvider } from "styled-components/macro";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { I18nProvider } from "./i18n";
import { AuthFailureProps, AuthProvider } from "./auth";
import { RootDocumentTitle } from "./title";
import { AnalyticsProvider } from "./analytics";

interface ContextProviderProps{
  readonly children?: React.ReactNode,
  readonly mock?: boolean,
  readonly defaultTheme: DefaultTheme,
  readonly configProvider: React.ComponentType,
  readonly globalStyles: React.ComponentType,
  readonly loadingComponent: React.ComponentType,
  readonly authFailureComponent: React.ComponentType<AuthFailureProps>,
  readonly companyNameKey: string,
  readonly appNameKey: string,
}

export function ContextProvider({
  children,
  mock,
  defaultTheme,
  globalStyles: GlobalStyle,
  configProvider: ConfigProvider,
  loadingComponent,
  authFailureComponent,
  companyNameKey,
  appNameKey,
}: ContextProviderProps): React.ReactElement {
  return (
    <ConfigProvider>
      <BrowserRouter>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyle />
          <I18nProvider loadingComponent={loadingComponent}>
            <RootDocumentTitle companyNameKey={companyNameKey} appNameKey={appNameKey}>
              <AuthProvider authenticatingComponent={loadingComponent} authFailureComponent={authFailureComponent} mock={mock}>
                <AnalyticsProvider>
                  {children}
                </AnalyticsProvider>
              </AuthProvider>
            </RootDocumentTitle>
          </I18nProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}
