import styled from "styled-components/macro";
import * as React from "react";
import { ReportDto, ReportStatus, SummaryNotesRequest } from "../../shared/dtos";
import { reportStyle } from "./reportStyle";
import { DataTransparencyReportSection } from "../checks/data-transparency/DataTransparencyReportSection";
import { SystemEqualityReportSection } from "../checks/system-equality/SystemEqualityReportSection";
import { SystemTransparencyReportSection } from "../checks/system-transparency/SystemTransparencyReportSection";
import { DataPrivacyReportSection } from "../checks/data-privacy/DataPrivacyReportSection";
import { SystemPurposeReportSection } from "../checks/system-purpose/SystemPurposeReportSection";
import { ReportComments } from "./ReportComments";
import { useTranslation } from "../../mf/utils/i18n";
import { SummaryNotesForm } from "./SummaryNotesForm";

const Frame = styled.div`
  margin: 1rem;
  @media print {
    margin: 0;
    padding: 0;
  }
`;
const Paper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border: 1px ${(props) => props.theme.greyLight} solid;
  width: 1400px;
  max-width: 100%;
  border-radius: 5px;
  background-color: white;
  padding: 1rem;
  @media print {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
  }
  ${reportStyle}
`;

interface Props {
  readonly report: ReportDto,
  readonly submitSummaryNotes: (values: SummaryNotesRequest) => Promise<void>,
}

export function Report({ report, submitSummaryNotes }: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Frame>
      <Paper>
        <section id="header" key="header">
          <h1>{t("app:REPORT_TEXT_TITLE")}</h1>
          <h2>{t("app:REPORT_TEXT_SUBTITLE", { name: report.name })}</h2>
          <p id="summary">{t("app:REPORT_TEXT_ABSTRACT")}</p>
        </section>
        <section id="contents" key="contents">
          <h3>{t("app:REPORT_TEXT_SECTION_CONTENTS")}</h3>
          <ul>
            <li><a href="#system-purpose">{t("app:SYSTEM_PURPOSE_CHECK_NAME")}</a></li>
            <li><a href="#data-transparency">{t("app:DATA_TRANSPARENCY_CHECK_NAME")}</a></li>
            <li><a href="#data-privacy">{t("app:DATA_PRIVACY_CHECK_NAME")}</a></li>
            <li><a href="#system-transparency">{t("app:SYSTEM_TRANSPARENCY_CHECK_NAME")}</a></li>
            <li><a href="#system-equality">{t("app:SYSTEM_EQUALITY_CHECK_NAME")}</a></li>
            <li><a href="#overview">{t("app:REPORT_TEXT_SECTION_OVERVIEW")}</a></li>
          </ul>
        </section>
        <section id="system-purpose" key="system-purpose">
          <h3>{t("app:SYSTEM_PURPOSE_CHECK_NAME")}</h3>
          <SystemPurposeReportSection data={report.systemPurpose} />
          <h4>{t("app:REPORT_TEXT_SUBSECTION_DISCUSSIONS")}</h4>
          <p><ReportComments comments={report.systemPurpose.comments} /></p>
        </section>
        <section id="data-transparency" key="data-transparency">
          <h3>{t("app:DATA_TRANSPARENCY_CHECK_NAME")}</h3>
          <DataTransparencyReportSection data={report.dataTransparency} />
          <h4>{t("app:REPORT_TEXT_SUBSECTION_DISCUSSIONS")}</h4>
          <p><ReportComments comments={report.dataTransparency.comments} /></p>
        </section>
        <section id="data-privacy" key="data-privacy">
          <h3>{t("app:DATA_PRIVACY_CHECK_NAME")}</h3>
          <DataPrivacyReportSection data={report.dataPrivacy} />
          <h4>{t("app:REPORT_TEXT_SUBSECTION_DISCUSSIONS")}</h4>
          <p><ReportComments comments={report.dataPrivacy.comments} /></p>
        </section>
        <section id="system-transparency" key="system-transparency">
          <h3>{t("app:SYSTEM_TRANSPARENCY_CHECK_NAME")}</h3>
          <SystemTransparencyReportSection data={report.systemTransparency} />
          <h4>{t("app:REPORT_TEXT_SUBSECTION_DISCUSSIONS")}</h4>
          <p><ReportComments comments={report.systemTransparency.comments} /></p>
        </section>
        <section id="system-equality" key="system-equality">
          <h3 id="system-equality">{t("app:SYSTEM_EQUALITY_CHECK_NAME")}</h3>
          <SystemEqualityReportSection data={report.systemEquality} />
          <h4>{t("app:REPORT_TEXT_SUBSECTION_DISCUSSIONS")}</h4>
          <p><ReportComments comments={report.systemEquality.comments} /></p>
        </section>
        <section id="overview" key="overview">
          <h3>{t("app:REPORT_TEXT_SECTION_OVERVIEW")}</h3>
          <h4>{t("app:REPORT_TEXT_SUBSECTION_SUMMARY")}</h4>
          {report.status === ReportStatus.PENDING && (<p><em>{t("app:REPORT_TEXT_CHECK_INCOMPLETE")}</em></p>)}
          {report.status === ReportStatus.PENDING_FINAL && (<p><SummaryNotesForm submitSummaryNotes={submitSummaryNotes} /></p>)}
          {(report.status === ReportStatus.SUCCESS || report.status === ReportStatus.FAILURE) && (<p>{report.summaryNotes || t("app:OPTIONAL_FIELD_FALLBACK")}</p>)}
          <h4>{t("app:REPORT_TEXT_SUBSECTION_DISCUSSIONS")}</h4>
          <p><ReportComments comments={report.comments} /></p>
        </section>
      </Paper>
    </Frame>
  );
}
