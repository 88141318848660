import * as React from "react";
import { CircularProgress } from "@material-ui/core";
import { PageFrame } from "../../mf/style/components/page/PageFrame";

interface Props {
  readonly light?: boolean,
}
export function LoadingPage({ light }: Props): React.ReactElement {
  return (
    <PageFrame testid="loading-page" mode="center" light={light}>
      <CircularProgress size={160} color={light ? "primary" : "secondary"} />
    </PageFrame>
  );
}

export function DefaultLoadingPage(): React.ReactElement {
  return (<LoadingPage />);
}
