import * as React from "react";
import { useFormikContext } from "formik";
import { TFunction, Trans } from "react-i18next";
import * as Translation from "../../../mf/utils/i18n";
import { CsvInput } from "../../app/CsvInput";
import { ColumnStats } from "../../app/ColumnStats";
import { FormColumnBlock } from "../../app/FormColumnBlock";
import { BooleanInput } from "../../app/BooleanInput";
import { SelectInput } from "../../app/SelectInput";
import { SystemEqualityData } from "../../../shared/dtos";

export const defaults: SystemEqualityData = {
  dataset: { name: undefined, body: undefined, pending: false, columns: undefined },
  fairnessMetric: "equal-opportunity",
  labelColumn: "",
  predictionColumn: "",
  privilegedLabel: "",
  protectedAttribute: {},
};

export function SystemEqualityForm(): React.ReactElement {
  const { t } = Translation.useTranslation();
  const tf = t as TFunction;

  const { values: { dataset: { columns, body }, predictionColumn, fairnessMetric }, setFieldValue } = useFormikContext<SystemEqualityData>();

  const uniqueTargetValues = React.useMemo<ReadonlyArray<string>>(
    () => (predictionColumn ? body?.map((row) => row?.[predictionColumn] ?? "")?.filter((val, i, self) => self.indexOf(val) === i) : undefined) ?? [],
    [predictionColumn, body],
  );

  const onDataSetChangeDependantFieldUpdates = React.useCallback((newColumns: ReadonlyArray<string>): Partial<SystemEqualityData> => ({
    labelColumn: newColumns?.[0] ?? "",
    predictionColumn: newColumns?.[0] ?? "",
    privilegedLabel: "",
    protectedAttribute: newColumns?.reduce((agg, c) => ({ ...agg, [c]: false }), {}) ?? {},
  }), []);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFieldValue("privilegedLabel", (uniqueTargetValues[0] ?? ""));
  }, [uniqueTargetValues, setFieldValue]);

  return (
    <>
      <CsvInput
        key="dataset"
        name="dataset"
        label={t("app:SYSTEM_EQUALITY_FIELD_DATASET_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_EQUALITY_FIELD_DATASET_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_EQUALITY_FIELD_DATASET_PLACEHOLDER")}
        onChangeDependantFieldUpdates={onDataSetChangeDependantFieldUpdates}
      />
      <SelectInput
        key="fairnessMetric"
        name="fairnessMetric"
        label={t("app:SYSTEM_EQUALITY_FIELD_FAIRNESS_METRIC_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_EQUALITY_FIELD_FAIRNESS_METRIC_DESCRIPTION" />}
      >
        <option key="equal-opportunity" value="equal-opportunity">
          {t("app:SYSTEM_EQUALITY_FIELD_FAIRNESS_METRIC_OPTION_EQUAL_OPPORTUNITY")}
        </option>
        <option key="demographic-parity" value="demographic-parity">
          {t("app:SYSTEM_EQUALITY_FIELD_FAIRNESS_METRIC_OPTION_DEMOGRAPHIC_PARITY")}
        </option>
        <option key="average-fairness" value="average-fairness">
          {t("app:SYSTEM_EQUALITY_FIELD_FAIRNESS_METRIC_OPTION_AVERAGE_FAIRNESS")}
        </option>
      </SelectInput>
      {columns ? (
        <>
          {fairnessMetric === "equal-opportunity" && (
          <SelectInput
            key="labelColumn"
            name="labelColumn"
            label={t("app:SYSTEM_EQUALITY_FIELD_LABEL_COLUMN_LABEL")}
            explanation={<Trans t={tf} i18nKey="app:SYSTEM_EQUALITY_FIELD_LABEL_COLUMN_DESCRIPTION" />}
          >
            {columns.map((column) => <option key={column} value={column}>{column}</option>)}
          </SelectInput>
          )}
          <SelectInput
            key="predictionColumn"
            name="predictionColumn"
            label={t("app:SYSTEM_EQUALITY_FIELD_PREDICTION_COLUMN_LABEL")}
            explanation={<Trans t={tf} i18nKey="app:SYSTEM_EQUALITY_FIELD_PREDICTION_COLUMN_DESCRIPTION" />}
          >
            {columns.map((column) => <option key={column} value={column}>{column}</option>)}
          </SelectInput>
          {uniqueTargetValues ? (
            <>
              {fairnessMetric !== "average-fairness" && (
                <SelectInput
                  key="privilegedLabel"
                  name="privilegedLabel"
                  label={t("app:SYSTEM_EQUALITY_FIELD_PRIVILEGED_LABEL_LABEL")}
                  explanation={<Trans t={tf} i18nKey="app:SYSTEM_EQUALITY_FIELD_PRIVILEGED_LABEL_DESCRIPTION" />}
                >
                  {uniqueTargetValues.map((targetValue) => <option key={targetValue} value={targetValue}>{targetValue}</option>)}
                </SelectInput>
              )}
              {columns.map((column, i) => (
                <FormColumnBlock first={i === 0} key={column}>
                  <ColumnStats name={column} data={body!} />
                  <BooleanInput
                    key={`protectedAttribute.${column}`}
                    name={`protectedAttribute.${column}`}
                    label={t("app:SYSTEM_EQUALITY_FIELD_PROTECTED_ATTRIBUTE_LABEL", { column })}
                    explanation={<Trans t={tf} i18nKey="app:SYSTEM_EQUALITY_FIELD_PROTECTED_ATTRIBUTE_DESCRIPTION" values={{ column }} />}
                  />
                </FormColumnBlock>
              ))}
            </>
          ) : (
            <p className="center"><em>{t("app:SYSTEM_EQUALITY_LABEL_COLUMN_REQUIRED")}</em></p>
          )}
        </>
      ) : (
        <p className="center"><em>{t("app:CHECK_PAGE_DATA_REQUIRED")}</em></p>
      )}
    </>
  );
}
