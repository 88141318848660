import * as React from "react";
import * as Translation from "../../mf/utils/i18n";
import { PageFrame, PageMode } from "../../mf/style/components/page/PageFrame";
import { BannerOverlay } from "../../mf/style/components/page/BannerOverlay";

interface Props {
  readonly children?: React.ReactNode,
  readonly testid: string,
  readonly mode?: PageMode,
}

export function Page({ children, mode, testid }: Props): React.ReactElement {
  const { t } = Translation.useTranslation();
  return (
    <PageFrame
      mode={mode}
      testid={testid}
      outer={(
        <BannerOverlay
          logoutAction={t("app:LOGOUT_ACTION")}
          languageHeader={t("app:LANGUAGE_PICKER_TITLE")}
        />
      )}
    >
      {children}
    </PageFrame>
  );
}
