import styled from "styled-components/macro";
import * as React from "react";

export const Divider = styled.div`
  height: 0;
  border-top: 1px solid ${(props) => props.theme.greyLight};
`;

interface Props {
  readonly className?: string,
  readonly testid?: string,
}

export function DropDownDivider({ className, testid }: Props): React.ReactElement {
  return (<Divider className={className} data-testid={testid} />);
}
