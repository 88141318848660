import { Async } from "../types";

export function headers(token: string): Headers {
  return new Headers({
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  });
}
export function extract<T>(response: Response): T {
  if (response.status >= 200 && response.status < 300) {
    return response.json() as unknown as T;
  }
  // eslint-disable-next-line no-throw-literal
  throw { message: `${response.status}: ${response.statusText}`, status: response.status } as Async<never>["error"];
}
