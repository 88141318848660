import * as React from "react";
import { useFormikContext } from "formik";
import { TFunction, Trans } from "react-i18next";
import { DataTransparencyData } from "../../../shared/dtos";
import * as Translation from "../../../mf/utils/i18n";
import { CsvInput } from "../../app/CsvInput";
import { BooleanInput } from "../../app/BooleanInput";
import { ColumnStats } from "../../app/ColumnStats";
import { FormColumnBlock } from "../../app/FormColumnBlock";
import { TextInput } from "../../app/TextInput";
import { SelectInput } from "../../app/SelectInput";

export const defaults: DataTransparencyData = {
  dataset: { name: undefined, body: undefined, pending: false, columns: undefined },
  dataContent: "",
  dataQuality: "",
  dataUse: "",
  dataOwner: "",
  columnContent: {},
  columnQuality: {},
  columnOffensive: {},
  columnUse: {},
  notes: "",
};

export function DataTransparencyForm(): React.ReactElement {
  const { t } = Translation.useTranslation();
  const tf = t as TFunction;

  const { values: { dataset: { columns, body } } } = useFormikContext<DataTransparencyData>();

  const onDataSetChangeDependantFieldUpdates = React.useCallback((newColumns: ReadonlyArray<string>): Partial<DataTransparencyData> => ({
    columnContent: newColumns?.reduce((agg, c) => ({ ...agg, [c]: "" }), {}) ?? {},
    columnQuality: newColumns?.reduce((agg, c) => ({ ...agg, [c]: "" }), {}) ?? {},
    columnOffensive: newColumns?.reduce((agg, c) => ({ ...agg, [c]: false }), {}) ?? {},
    columnUse: newColumns?.reduce((agg, c) => ({ ...agg, [c]: "feature" }), {}) ?? {},
  }), []);

  return (
    <>
      <CsvInput
        key="dataset"
        name="dataset"
        label={t("app:DATA_TRANSPARENCY_FIELD_DATASET_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_DATASET_DESCRIPTION" />}
        placeholder={t("app:DATA_TRANSPARENCY_FIELD_DATASET_PLACEHOLDER")}
        onChangeDependantFieldUpdates={onDataSetChangeDependantFieldUpdates}
      />
      <TextInput
        key="dataContent"
        name="dataContent"
        label={t("app:DATA_TRANSPARENCY_FIELD_DATA_CONTENT_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_DATA_CONTENT_DESCRIPTION" />}
        placeholder={t("app:DATA_TRANSPARENCY_FIELD_DATA_CONTENT_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="dataQuality"
        name="dataQuality"
        label={t("app:DATA_TRANSPARENCY_FIELD_DATA_QUALITY_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_DATA_QUALITY_DESCRIPTION" />}
        placeholder={t("app:DATA_TRANSPARENCY_FIELD_DATA_QUALITY_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="dataUse"
        name="dataUse"
        label={t("app:DATA_TRANSPARENCY_FIELD_DATA_USE_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_DATA_USE_DESCRIPTION" />}
        placeholder={t("app:DATA_TRANSPARENCY_FIELD_DATA_USE_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="dataOwner"
        name="dataOwner"
        label={t("app:DATA_TRANSPARENCY_FIELD_DATA_OWNER_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_DATA_OWNER_DESCRIPTION" />}
        placeholder={t("app:DATA_TRANSPARENCY_FIELD_DATA_OWNER_PLACEHOLDER")}
        size={3}
      />
      {columns !== undefined ? (
        <>
          {columns.map((column, i) => (
            <FormColumnBlock first={i === 0} key={column}>
              <ColumnStats name={column} data={body!} />
              <TextInput
                key={`columnContent.${column}`}
                name={`columnContent.${column}`}
                label={t("app:DATA_TRANSPARENCY_FIELD_COLUMN_CONTENT_LABEL", { column })}
                explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_COLUMN_CONTENT_DESCRIPTION" values={{ column }} />}
                placeholder={t("app:DATA_TRANSPARENCY_FIELD_COLUMN_CONTENT_PLACEHOLDER", { column })}
                size={6}
              />
              <TextInput
                key={`columnQuality.${column}`}
                name={`columnQuality.${column}`}
                label={t("app:DATA_TRANSPARENCY_FIELD_COLUMN_QUALITY_LABEL", { column })}
                explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_COLUMN_QUALITY_DESCRIPTION" values={{ column }} />}
                placeholder={t("app:DATA_TRANSPARENCY_FIELD_COLUMN_QUALITY_PLACEHOLDER", { column })}
                size={6}
              />
              <BooleanInput
                key={`columnOffensive.${column}`}
                name={`columnOffensive.${column}`}
                label={t("app:DATA_TRANSPARENCY_FIELD_COLUMN_OFFENSIVE_LABEL", { column })}
                explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_COLUMN_OFFENSIVE_DESCRIPTION" values={{ column }} />}
              />
              <SelectInput
                key={`columnUse.${column}`}
                name={`columnUse.${column}`}
                label={t("app:DATA_TRANSPARENCY_FIELD_COLUMN_USE_LABEL", { column })}
                explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_COLUMN_USE_DESCRIPTION" values={{ column }} />}
              >
                <option key="feature" value="feature">
                  {t("app:DATA_TRANSPARENCY_FIELD_COLUMN_USE_OPTION_FEATURE")}
                </option>
                <option key="label" value="label">
                  {t("app:DATA_TRANSPARENCY_FIELD_COLUMN_USE_OPTION_LABEL")}
                </option>
              </SelectInput>
            </FormColumnBlock>
          ))}
          <TextInput
            key="notes"
            name="notes"
            label={t("app:DATA_TRANSPARENCY_FIELD_NOTES_LABEL")}
            explanation={<Trans t={tf} i18nKey="app:DATA_TRANSPARENCY_FIELD_NOTES_DESCRIPTION" />}
            placeholder={t("app:DATA_TRANSPARENCY_FIELD_NOTES_PLACEHOLDER")}
            size={6}
          />
        </>
      ) : (
        <p className="center"><em>{t("app:CHECK_PAGE_DATA_REQUIRED")}</em></p>
      )}
    </>
  );
}
