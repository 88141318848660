import * as React from "react";
import { ReportStatus, SystemPurposeData } from "../../../shared/dtos";
import { useTranslation } from "../../../mf/utils/i18n";
import { ReportSectionProps } from "../../app/CheckLayout";

export function SystemPurposeReportSection({ data, showFinal }: ReportSectionProps<SystemPurposeData>): React.ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t("app:REPORT_TEXT_SUBSECTION_CONFIGURATION")}</h4>
      {data.status === ReportStatus.PENDING || data.status === ReportStatus.RUNNING || data.status === ReportStatus.ERROR || (data.status === ReportStatus.PENDING_FINAL && !showFinal) ? (
        <p><em>{t("app:REPORT_TEXT_CHECK_INCOMPLETE")}</em></p>
      ) : (
        <>
          <p>
            {t("app:SYSTEM_PURPOSE_FIELD_PURPOSE_LABEL")}: {data.in!.purpose || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_PURPOSE_FIELD_OWNER_LABEL")}: {data.in!.owner || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:SYSTEM_PURPOSE_FIELD_NOTES_LABEL")}: {data.in!.notes || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
          </p>
        </>
      )}
    </>
  );
}
