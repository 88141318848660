import React from "react";
import styled from "styled-components/macro";
import { CheckForm } from "../app/CheckForm";
import { validateSummaryNotesRequest } from "../../shared/validation";
import { SummaryNotesRequest } from "../../shared/dtos";
import { TextInput } from "../app/TextInput";
import * as Translation from "../../mf/utils/i18n";

const initialValues = { message: "" };

const TextBox = styled(TextInput)`
  margin-bottom: 0.5em
`;
interface Props {
  readonly submitSummaryNotes: (values: SummaryNotesRequest) => Promise<void>,
}
export function SummaryNotesForm({ submitSummaryNotes }: Props): React.ReactElement {
  const { t } = Translation.useTranslation();
  return (
    <CheckForm initialValues={initialValues} onSubmit={submitSummaryNotes} validateAndTranslate={validateSummaryNotesRequest} hideTitle>
      <TextBox name="message" label={t("app:REPORT_FIELD_SUMMARY_LABEL")} placeholder={t("app:REPORT_FIELD_SUMMARY_PLACEHOLDER")} size={6} />
    </CheckForm>
  );
}
