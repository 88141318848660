import * as React from "react";
import { DataPrivacyData, IdType, PrivacyMitigation, ReportStatus } from "../../../shared/dtos";
import { useTranslation } from "../../../mf/utils/i18n";
import { ReportSectionProps } from "../../app/CheckLayout";
import { ColumnStats } from "../../app/ColumnStats";

const idTypeNames: { [val in IdType]: string } = {
  na: "app:DATA_PRIVACY_FIELD_ID_TYPE_OPTION_NA",
  direct: "app:DATA_PRIVACY_FIELD_ID_TYPE_OPTION_DIRECT",
  quasi: "app:DATA_PRIVACY_FIELD_ID_TYPE_OPTION_QUASI",
};

const privacyMitigationNames: { [val in PrivacyMitigation]: string } = {
  none: "app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_NONE",
  pseudo: "app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_PSEUDO",
  rounding: "app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_ROUNDING",
  truncation: "app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_TRUNCATION",
  categorisation: "app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_CATEGORISATION",
  kanonymity: "app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_KANONYMITY",
  noise: "app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_NOISE",
  differential: "app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_DIFFERENTIAL",
  other: "app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_OTHER",
};
export function DataPrivacyReportSection({ data, showFinal }: ReportSectionProps<DataPrivacyData>): React.ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t("app:REPORT_TEXT_SUBSECTION_CONFIGURATION")}</h4>
      {data.status === ReportStatus.PENDING || data.status === ReportStatus.RUNNING || data.status === ReportStatus.ERROR || (data.status === ReportStatus.PENDING_FINAL && !showFinal) ? (
        <p><em>{t("app:REPORT_TEXT_CHECK_INCOMPLETE")}</em></p>
      ) : (
        <>
          <p>
            {t("app:DATA_PRIVACY_FIELD_DATASET_LABEL")}: {data.in!.dataset.name || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {t("app:DATA_PRIVACY_FIELD_NOTES_LABEL")}: {data.in!.notes || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
            {data.in!.dataset.columns!.map((column) => (
              <>
                <ColumnStats name={column} data={data.in!.dataset.body!} left />
                {t("app:DATA_PRIVACY_FIELD_ID_TYPE_LABEL")}: {t(idTypeNames[data.in!.idType[column]] || "app:OPTIONAL_FIELD_FALLBACK")}<br />
                {t("app:DATA_PRIVACY_FIELD_MITIGATION_LABEL")}: {t(privacyMitigationNames[data.in!.mitigation[column]] || "app:OPTIONAL_FIELD_FALLBACK")}<br />
                {t("app:DATA_PRIVACY_FIELD_MITIGATION_COMMENTS_LABEL")}: {data.in!.mitigationComments[column] || t("app:OPTIONAL_FIELD_FALLBACK")}<br />
              </>
            ))}
          </p>
        </>
      )}
    </>
  );
}
