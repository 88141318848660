/* eslint-disable @typescript-eslint/ban-ts-comment,no-param-reassign,@typescript-eslint/no-unsafe-member-access */
// noinspection SuspiciousTypeOfGuard

import { AddCommentRequest, CreateReportRequest, CSV, DataPrivacyData, DataTransparencyData, PerColumn, PerGroup, SystemEqualityData, SystemEqualityFinal, SystemPurposeData, SystemTransparencyData, SummaryNotesRequest } from "./dtos";
import { Mutable } from "./utils";

type TFunc = (key: string, bag?: { [p: string]: string | number }) => string;
export declare type Errors<Values> = {
  // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/array-type,@typescript-eslint/no-explicit-any
  [K in keyof Values]?: Values[K] extends any[] ? Values[K][number] extends object ? Errors<Values[K][number]>[] | string | string[] : string | string[] : Values[K] extends object ? Errors<Values[K]> : string;
};

const includes = (value: string, list: ReadonlyArray<string>): boolean =>
  // @ts-ignore
  // eslint-disable-next-line implicit-arrow-linebreak
  list.includes(value);

const dataIncludes = (value: string, data: ReadonlyArray<Record<string, string>>, column: string): boolean =>
  // @ts-ignore
  // eslint-disable-next-line implicit-arrow-linebreak
  data.findIndex((row) => row?.[column] === value) !== -1;

function checkRequiredString(value: string | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, t: TFunc) {
  if (!value || typeof value !== "string") {
    errors[errorTarget] = t("app:FIELD_VALIDATION_REQUIRED");
  }
}

function checkOptionalString(value: string | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, t: TFunc) {
  if (typeof value !== "string") {
    errors[errorTarget] = t("app:FIELD_VALIDATION_REQUIRED");
  }
}

function checkRequiredBoolean(value: boolean | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, t: TFunc) {
  if (typeof value !== "boolean") {
    errors[errorTarget] = t("app:FIELD_VALIDATION_REQUIRED");
  }
}

function checkRequiredOption(value: string | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, options: ReadonlyArray<string>, t: TFunc) {
  if (!value || typeof value !== "string") {
    errors[errorTarget] = t("app:FIELD_VALIDATION_REQUIRED");
  } else if (!includes(value, options)) {
    errors[errorTarget] = t("app:FIELD_VALIDATION_UNKNOWN");
  }
}

function checkOptionalOption(value: string | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, options: ReadonlyArray<string>, t: TFunc) {
  if (value !== undefined) {
    checkRequiredOption(value, errorTarget, errors, options, t);
  }
}

function checkRequiredColumn(value: string | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, columns: ReadonlyArray<string> | undefined, t: TFunc) {
  if (columns !== undefined && Array.isArray(columns)) {
    checkRequiredOption(value, errorTarget, errors, columns, t);
  }
}

function checkRequiredColumnValue(value: string | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, column: string | undefined, data: ReadonlyArray<Record<string, string>> | undefined, t: TFunc) {
  if (column !== undefined && typeof column === "string" && data !== undefined && Array.isArray(data)) {
    if (!value || typeof value !== "string") {
      errors[errorTarget] = t("app:FIELD_VALIDATION_REQUIRED");
    } else if (!dataIncludes(value, data, column)) {
      errors[errorTarget] = t("app:FIELD_VALIDATION_UNKNOWN");
    }
  }
}

function checkDataset(value: CSV, errorTarget: string, errors: Errors<Record<string, unknown>>, t: TFunc) {
  if (!value.name || typeof value.name !== "string") {
    // @ts-ignore
    errors[errorTarget] = t("app:FIELD_VALIDATION_REQUIRED");
  } else if (value.pending) {
    // @ts-ignore
    errors[errorTarget] = t("app:FIELD_VALIDATION_PROCESSING");
  } else if (!value.body || !Array.isArray(value.body) || !value.columns || !Array.isArray(value.columns)) {
    // @ts-ignore
    errors[errorTarget] = t("app:FIELD_VALIDATION_EMPTY_FILE");
  }
}
//
// function checkRequiredPerColumnString(values: PerColumn<string>, columns: ReadonlyArray<string> | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, t: TFunc) {
//   if (columns && Array.isArray(columns)) {
//     const subErrors: Errors<PerColumn<string>> = {};
//     columns.forEach((key) => {
//       checkRequiredString(values[key], key, subErrors, t);
//     });
//     if (Object.keys(subErrors).length > 0) {
//       // @ts-ignore
//       errors[errorTarget] = subErrors;
//     }
//   }
// }

function checkOptionalPerColumnString(values: PerColumn<string>, columns: ReadonlyArray<string> | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, t: TFunc) {
  if (columns && Array.isArray(columns)) {
    const subErrors: Errors<PerColumn<string>> = {};
    columns.forEach((key) => {
      checkOptionalString(values[key], key, subErrors, t);
    });
    if (Object.keys(subErrors).length > 0) {
      // @ts-ignore
      errors[errorTarget] = subErrors;
    }
  }
}

function checkRequiredPerColumnBoolean(values: PerColumn<boolean>, columns: ReadonlyArray<string> | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, t: TFunc) {
  if (columns && Array.isArray(columns)) {
    const subErrors: Errors<PerColumn<string>> = {};
    columns.forEach((key) => {
      checkRequiredBoolean(values[key], key, subErrors, t);
    });
    if (Object.keys(subErrors).length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errors[errorTarget] = subErrors;
    }
  }
}

function checkRequiredPerColumnOption(values: PerColumn<string>, columns: ReadonlyArray<string> | undefined, errorTarget: string, errors: Errors<Record<string, unknown>>, options: ReadonlyArray<string>, t: TFunc) {
  if (columns && Array.isArray(columns)) {
    const subErrors: Errors<PerColumn<string>> = {};
    columns.forEach((key) => {
      checkRequiredOption(values[key], key, subErrors, options, t);
    });
    if (Object.keys(subErrors).length > 0) {
      // @ts-ignore
      errors[errorTarget] = subErrors;
    }
  }
}

export const validateId = (id: string, t: TFunc = (s) => s): Errors<{ id: string }> => {
  const errors: Errors<Mutable<{ id: string }>> = {};
  checkRequiredString(id, "id", errors, t);
  return errors;
};

export const validateCreateReportRequest = (values: CreateReportRequest, t: TFunc = (s) => s): Errors<CreateReportRequest> => {
  const errors: Errors<Mutable<CreateReportRequest>> = {};
  checkRequiredString(values.name, "name", errors, t);
  return errors;
};

const steps: ReadonlyArray<string> = ["systemPurpose", "dataTransparency", "dataPrivacy", "systemTransparency", "systemEquality"];
export const validateAddCommentRequest = (values: AddCommentRequest, t: TFunc = (s) => s): Errors<AddCommentRequest> => {
  const errors: Errors<Mutable<AddCommentRequest>> = {};
  checkRequiredString(values.message, "message", errors, t);
  checkRequiredString(values.avatar, "avatar", errors, t);
  checkOptionalOption(values.step, "step", errors, steps, t);
  return errors;
};

export const validateSystemPurposeData = (values: SystemPurposeData, t: TFunc = (s) => s): Errors<SystemPurposeData> => {
  const errors: Errors<Mutable<SystemPurposeData>> = {};
  checkRequiredString(values.purpose, "purpose", errors, t);
  checkRequiredString(values.owner, "owner", errors, t);
  checkOptionalString(values.notes, "notes", errors, t);
  return errors;
};

export const validateSystemTransparencyData = (values: SystemTransparencyData, t: TFunc = (s) => s): Errors<SystemTransparencyData> => {
  const errors: Errors<Mutable<SystemTransparencyData>> = {};
  checkRequiredString(values.details, "details", errors, t);
  checkRequiredString(values.balancedInterests, "balancedInterests", errors, t);
  checkRequiredString(values.feedbackLoops, "feedbackLoops", errors, t);
  checkRequiredString(values.systemOwner, "systemOwner", errors, t);
  checkRequiredString(values.technicalDescription, "technicalDescription", errors, t);
  checkRequiredString(values.metrics, "metrics", errors, t);
  checkRequiredString(values.ethical, "ethical", errors, t);
  checkRequiredString(values.modelOwner, "modelOwner", errors, t);
  checkRequiredString(values.decisionExplanation, "decisionExplanation", errors, t);
  checkRequiredString(values.humanIntervention, "humanIntervention", errors, t);
  checkRequiredString(values.modelLogic, "modelLogic", errors, t);
  checkOptionalString(values.notes, "notes", errors, t);
  return errors;
};

export const validateSystemEqualityData = (values: SystemEqualityData, t: TFunc = (s) => s): Errors<SystemEqualityData> => {
  const errors: Errors<Mutable<SystemEqualityData>> = {};
  checkDataset(values.dataset, "dataset", errors, t);
  checkRequiredOption(values.fairnessMetric, "fairnessMetric", errors, ["equal-opportunity", "demographic-parity", "average-fairness"], t);
  if (values.fairnessMetric === "equal-opportunity") {
    checkRequiredColumn(values.labelColumn, "labelColumn", errors, values.dataset.columns, t);
  }
  checkRequiredColumn(values.predictionColumn, "predictionColumn", errors, values.dataset.columns, t);
  if (values.fairnessMetric !== "average-fairness") {
    checkRequiredColumnValue(values.privilegedLabel, "privilegedLabel", errors, values.predictionColumn, values.dataset.body, t);
  }
  checkRequiredPerColumnBoolean(values.protectedAttribute, values.dataset.columns, "protectedAttribute", errors, t);
  return errors;
};

export const validateSystemEqualityFinal = (values: SystemEqualityFinal, t: TFunc = (s) => s): Errors<SystemEqualityFinal> => {
  const errors: Errors<Mutable<SystemEqualityFinal>> = {};
  if (!values.justifications || typeof values.justifications !== "object") {
    // @ts-ignore
    errors.justifications = t("app:FIELD_VALIDATION_UNKNOWN");
    return errors;
  }
  if (!values.overrideFair || typeof values.overrideFair !== "object") {
    // @ts-ignore
    errors.overrideFair = t("app:FIELD_VALIDATION_UNKNOWN");
    return errors;
  }
  const cols = [...Object.keys(values.justifications), ...Object.keys(values.overrideFair)].filter((val, i, self) => self.indexOf(val) === i);
  const justificationsErrors: Errors<PerColumn<PerGroup<string>>> = {};
  const overrideFairErrors: Errors<PerColumn<PerGroup<boolean>>> = {};
  cols.forEach((col) => {
    if (!values.justifications[col] || typeof values.justifications[col] !== "object") {
      // @ts-ignore
      justificationsErrors[col] = t("app:FIELD_VALIDATION_UNKNOWN");
      return;
    }
    if (!values.overrideFair[col] || typeof values.overrideFair[col] !== "object") {
      // @ts-ignore
      overrideFairErrors[col] = t("app:FIELD_VALIDATION_UNKNOWN");
      return;
    }

    const groups = [...Object.keys(values.justifications[col]), ...Object.keys(values.overrideFair[col])].filter((val, i, self) => self.indexOf(val) === i);

    const justificationsColumnErrors: Errors<PerGroup<string>> = {};
    groups.forEach((group) => {
      if (typeof values.justifications[col][group] !== "string" || (!values.justifications[col][group] && values.overrideFair?.[col]?.[group])) {
        // @ts-ignore
        justificationsColumnErrors[group] = t("app:FIELD_VALIDATION_REQUIRED");
      }
    });
    if (Object.keys(justificationsColumnErrors).length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      justificationsErrors[col] = justificationsColumnErrors;
    }

    const overrideFairColumnErrors: Errors<PerGroup<boolean>> = {};
    groups.forEach((group) => {
      if (typeof values.overrideFair[col][group] !== "boolean") {
        // @ts-ignore
        overrideFairColumnErrors[group] = t("app:FIELD_VALIDATION_REQUIRED");
      }
    });
    if (Object.keys(overrideFairColumnErrors).length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      overrideFairErrors[col] = overrideFairColumnErrors;
    }
  });
  if (Object.keys(justificationsErrors).length > 0) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    errors.justifications = justificationsErrors;
  }
  if (Object.keys(overrideFairErrors).length > 0) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    errors.overrideFair = overrideFairErrors;
  }
  return errors;
};

export const validateDataTransparencyData = (values: DataTransparencyData, t: TFunc = (s) => s): Errors<DataTransparencyData> => {
  const errors: Errors<Mutable<DataTransparencyData>> = {};
  checkDataset(values.dataset, "dataset", errors, t);
  checkRequiredString(values.dataContent, "dataContent", errors, t);
  checkRequiredString(values.dataQuality, "dataQuality", errors, t);
  checkRequiredString(values.dataUse, "dataUse", errors, t);
  checkRequiredString(values.dataOwner, "dataOwner", errors, t);
  checkOptionalPerColumnString(values.columnContent, values.dataset.columns, "columnContent", errors, t);
  checkOptionalPerColumnString(values.columnQuality, values.dataset.columns, "columnQuality", errors, t);
  checkRequiredPerColumnBoolean(values.columnOffensive, values.dataset.columns, "columnOffensive", errors, t);
  checkRequiredPerColumnOption(values.columnUse, values.dataset.columns, "columnUse", errors, ["feature", "label"], t);
  checkOptionalString(values.notes, "notes", errors, t);
  return errors;
};

export const validateDataPrivacyData = (values: DataPrivacyData, t: TFunc = (s) => s): Errors<DataPrivacyData> => {
  const errors: Errors<Mutable<DataPrivacyData>> = {};
  checkDataset(values.dataset, "dataset", errors, t);
  checkRequiredPerColumnOption(values.idType, values.dataset.columns, "idType", errors, ["direct", "quasi", "na"], t);
  checkRequiredPerColumnOption(values.mitigation, values.dataset.columns, "idType", errors, ["none", "pseudo", "rounding", "truncation", "categorisation", "noise", "kanonymity", "differential", "other"], t);
  checkOptionalPerColumnString(values.mitigationComments, values.dataset.columns, "idType", errors, t);
  checkOptionalString(values.notes, "notes", errors, t);
  return errors;
};

export const validateSummaryNotesRequest = (values: SummaryNotesRequest, t: TFunc = (s) => s): Errors<SummaryNotesRequest> => {
  const errors: Errors<Mutable<SummaryNotesRequest>> = {};
  checkRequiredString(values.message, "message", errors, t);
  return errors;
};
