import React from "react";
import { Analytics, AnalyticsInstance } from "analytics";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mixpanelPlugin from "@analytics/mixpanel";

import { BasicConfig, useBasicConfig } from "./config";
import { useAuth, User } from "./auth";
import { useTranslation } from "./i18n";

const appName = "Mind Foundry Certifai";
const app = "certifai";
interface AnalyticsEvent { [key: string]: string | number | Date | boolean }
interface AnalyticsMetadata {
  readonly route: {
    readonly url: string,
    readonly domain: string,
    readonly path: string,
    readonly search: string | null,
    readonly hash: string | null,
    readonly protocol: string,
    readonly title: string,
  },
  readonly browser: {
    readonly width: number,
    readonly height: number,
    readonly userAgent: string | null,
    readonly platform: string | null,
  },
  readonly app: {
    readonly name: string,
    readonly version: string,
  },
  readonly account: {
    readonly id: string | null,
    readonly name: string | null,
  },
  readonly user: {
    readonly id: string,
    readonly sub: string | null,
    readonly name: string | null,
    readonly firstname: string | null,
    readonly lastname: string | null,
    readonly email: string | null,
    readonly support: boolean,
  },
  readonly language: {
    readonly short: string | null,
    readonly full: string | null,
  },
}
const getMeta = (config: BasicConfig, user: User | null, language: string): AnalyticsMetadata => ({
  route: {
    url: window.location.href,
    domain: window.location.hostname,
    path: window.location.pathname,
    search: window.location.search ?? null,
    hash: window.location.hash ?? null,
    protocol: window.location.protocol,
    title: window.document.title,
  },
  browser: {
    width: window.innerWidth,
    height: window.innerHeight,
    userAgent: window.navigator.userAgent,
    platform: window.navigator.platform,
  },
  app: {
    name: appName,
    version: config.version,
  },
  account: {
    id: config.support.account ?? null,
    name: config.support.accountName ?? null,
  },
  user: {
    id: `${app}:${config.support.account || "unknown"}:${user?.sub || "anonymous"}`,
    sub: user?.sub || "anonymous",
    name: user?.name ?? null,
    firstname: user?.givenName ?? null,
    lastname: user?.familyName ?? null,
    email: user?.email ?? null,
    support: user?.isSupport ?? false,
  },
  language: {
    short: language?.split("-")?.[0] ?? null,
    full: language ?? null,
  },
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
export const getSource = (event: React.BaseSyntheticEvent): string => event?.currentTarget?.getAttribute("data-analytics") ?? event?.currentTarget?.getAttribute("data-e2e") ?? "unknown";

const yieldPromise = (createPromise: () => Promise<void>) => {
  // Intentionally dangling promise!
  new Promise<void>((resolve, reject): void => {
    setTimeout(() => {
      try {
        createPromise()
          .then(() => resolve())
          .catch((e) => reject(e));
      } catch (e) {
        reject(e); // Make sync errors async
      }
    }, 0); // setTimeout 0 yields thread
  }).catch((e) => {
    // eslint-disable-next-line no-console
    console.warn("Analytics error", e);
  });
};

interface IAnalyticsService {
  track: (eventGroup: string, eventKey: string, event: AnalyticsEvent, metadata: AnalyticsMetadata) => Promise<void>,
  identify: (metadata: AnalyticsMetadata) => Promise<void>,
  page: (metadata: AnalyticsMetadata) => Promise<void>,
}

export interface AnalyticsProxy {
  readonly track: (eventGroup: string, eventKey: string, event: AnalyticsEvent) => void,
  readonly identify: () => void,
  readonly page: () => void,
}

export const AnalyticsContext = React.createContext<AnalyticsProxy | undefined>(undefined);

interface Props {
  readonly children: React.ReactNode,
}
export function AnalyticsProvider({ children }: Props): React.ReactElement {
  const config = useBasicConfig();
  const { user } = useAuth();
  const { language } = useTranslation();

  const analyticsPkg = React.useMemo<AnalyticsInstance>(() => Analytics({
    app: appName,
    version: config.version,
    debug: config.support.analytics.enableAnalytics && process.env.NODE_ENV === "development", // To view these, used redux dev tools and swap to the other store
    plugins: (!config.support.analytics.enableAnalytics || process.env.NODE_ENV === "development") ? [] : [
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      mixpanelPlugin({
        token: config.support.analytics.mixpanelToken,
        pageEvent: "page",
        options: {
          api_host: `${window.location.origin}/record/api`,
          cdn: `${window.location.origin}/record/cdn`,
        },
        customScriptSrc: `${window.location.origin}/record/entrypoint.js`,
      }),
    ],
  }), [config]);

  const analyticsService = React.useMemo<IAnalyticsService>(() => ({
    track: async (eventGroup, eventKey, event, metadata) => analyticsPkg.track(`${eventGroup}:${eventKey}`, { ...event, ...metadata }),
    identify: async (metadata) => analyticsPkg.identify(metadata.user.id, { $email: metadata.user.email, $name: metadata.user.name, ...metadata }),
    page: async (metadata) => analyticsPkg.page({ ...metadata.route, width: `${metadata.browser.width}`, height: `${metadata.browser.height}`, search: metadata.route.search ?? undefined, ...metadata }),
  }), [analyticsPkg]);

  const analytics = React.useMemo<AnalyticsProxy>(() => ({
    track: (eventGroup, eventKey, event): void => yieldPromise(() => analyticsService.track(eventGroup, eventKey, event, getMeta(config, user, language))),
    identify: (): void => yieldPromise(() => analyticsService.identify(getMeta(config, user, language))),
    page: (): void => yieldPromise(() => analyticsService.page(getMeta(config, user, language))),
  }), [analyticsService, config, user, language]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}
