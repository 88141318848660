import { useCallback, useState, useEffect } from "react";
import useInterval from "use-interval";
import { isDeepEqual } from "@material-ui/data-grid";
import { useAuth } from "../../mf/utils/auth";
import { AddCommentRequest, DataPrivacyData, DataTransparencyData, ReportDto, ReportId, SummaryNotesRequest, SystemEqualityData, SystemEqualityFinal, SystemPurposeData, SystemTransparencyData } from "../../shared/dtos";
import { Async } from "../types";
import { fetchReport, putComment, putDataPrivacyStep, putDataTransparencyStep, putSystemEqualityStep, putSystemPurposeStep, putSystemTransparencyStep, putSystemEqualityFinal, putSummaryNotes } from "./reportApi";

interface Result {
  readonly report: Async<ReportDto>,
  readonly addComment: (message: AddCommentRequest) => Promise<void>,
  readonly runSystemPurposeStep: (request: SystemPurposeData) => Promise<void>,
  readonly runDataTransparencyStep: (request: DataTransparencyData) => Promise<void>,
  readonly runDataPrivacyStep: (request: DataPrivacyData) => Promise<void>,
  readonly runSystemTransparencyStep: (request: SystemTransparencyData) => Promise<void>,
  readonly runSystemEqualityStep: (request: SystemEqualityData) => Promise<void>,
  readonly submitSystemEqualityFinal: (request: SystemEqualityFinal) => Promise<void>,
  readonly submitSummaryNotes: (request: SummaryNotesRequest) => Promise<void>,
}
export function useReport(id: ReportId): Result {
  const { getAccessToken } = useAuth();
  const [report, setReport] = useState<Async<ReportDto>>({ loading: true });

  const { value: oldReport } = report;
  const setReportChecked = useCallback((newReport: ReportDto) => {
    if (!oldReport || !isDeepEqual(newReport, oldReport)) {
      // Skip changes if there isn't actually a change (avoid duplicate histograms calculation)
      setReport({ value: newReport });
    }
  }, [setReport, oldReport]);

  const getReport = useCallback((targetId: ReportId, ignoreError = false) => fetchReport(targetId, getAccessToken)
    .then((value) => setReportChecked(value))
    .catch((error: Error) => !ignoreError && setReport({ error })), [getAccessToken, setReportChecked]);

  const pollReport = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getReport(id, true);
  }, [id, getReport]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getReport(id);
  }, [getReport, id]);

  useInterval(pollReport, 5000, false);

  const addComment = useCallback(async (request: AddCommentRequest): Promise<void> => {
    const reportDto = await putComment(id, request, getAccessToken);
    setReport({ value: reportDto });
  }, [id, setReport, getAccessToken]);

  const runSystemPurposeStep = useCallback(async (request: SystemPurposeData): Promise<void> => {
    const reportDto = await putSystemPurposeStep(id, request, getAccessToken);
    setReport({ value: reportDto });
  }, [id, setReport, getAccessToken]);

  const runDataTransparencyStep = useCallback(async (request: DataTransparencyData): Promise<void> => {
    const reportDto = await putDataTransparencyStep(id, request, getAccessToken);
    setReport({ value: reportDto });
  }, [id, setReport, getAccessToken]);

  const runDataPrivacyStep = useCallback(async (request: DataPrivacyData): Promise<void> => {
    const reportDto = await putDataPrivacyStep(id, request, getAccessToken);
    setReport({ value: reportDto });
  }, [id, setReport, getAccessToken]);

  const runSystemTransparencyStep = useCallback(async (request: SystemTransparencyData): Promise<void> => {
    const reportDto = await putSystemTransparencyStep(id, request, getAccessToken);
    setReport({ value: reportDto });
  }, [id, setReport, getAccessToken]);

  const runSystemEqualityStep = useCallback(async (request: SystemEqualityData): Promise<void> => {
    const reportDto = await putSystemEqualityStep(id, request, getAccessToken);
    setReport({ value: reportDto });
  }, [id, setReport, getAccessToken]);

  const submitSystemEqualityFinal = useCallback(async (request: SystemEqualityFinal): Promise<void> => {
    const reportDto = await putSystemEqualityFinal(id, request, getAccessToken);
    setReport({ value: reportDto });
  }, [id, setReport, getAccessToken]);

  const submitSummaryNotes = useCallback(async (request: SummaryNotesRequest): Promise<void> => {
    const reportDto = await putSummaryNotes(id, request, getAccessToken);
    setReport({ value: reportDto });
  }, [id, setReport, getAccessToken]);

  return {
    report,
    addComment,
    runSystemPurposeStep,
    runDataTransparencyStep,
    runDataPrivacyStep,
    runSystemTransparencyStep,
    runSystemEqualityStep,
    submitSystemEqualityFinal,
    submitSummaryNotes,
  };
}
