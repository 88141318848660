import React from "react";
import { useFormik, FormikConfig, FormikHelpers, FormikValues } from "formik";

// ugh formik
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useForm<T extends FormikValues>(config: FormikConfig<T>) {
  // Why on earth isn't this the default behavior of formik!
  const [submitError, setSubmitError] = React.useState<Error | undefined>(undefined);
  const baseSubmit = config.onSubmit;
  const onSubmit = React.useCallback((values: T, helpers: FormikHelpers<T>): void | Promise<void> => {
    setSubmitError(undefined);
    const promise = baseSubmit(values, helpers);
    if (promise !== undefined) {
      return promise.catch((e: Error) => {
        setSubmitError(e);
      });
    }
    return undefined;
  }, [baseSubmit, setSubmitError]);
  const baseReset = config.onReset;

  const onReset = React.useCallback((values: T, helpers: FormikHelpers<T>): void => {
    if (baseReset) {
      baseReset(values, helpers);
    }
    setSubmitError(undefined);
  }, [baseReset, setSubmitError]);
  const formik = useFormik<T>({ ...config, onSubmit, onReset });
  return {
    ...formik,
    submitError,
  };
}
