import styled from "styled-components/macro";
import * as React from "react";
import { DropDownItem } from "./DropDownItem";

const SectionItem = styled(DropDownItem)`
  padding-left: ${(props) => props.theme.dropdownPad * 3}px;
`;

interface Props {
  readonly onClick?: () => void,
  readonly selected?: boolean,
  readonly disabled?: boolean,
  readonly danger?: boolean,
  readonly children?: React.ReactNode,
  readonly className?: string,
  readonly testid?: string,
}

export function DropDownSectionItem({ onClick, selected, disabled, danger, children, className, testid }: Props): React.ReactElement {
  return (<SectionItem onClick={onClick} selected={selected} disabled={disabled} danger={danger} className={className} testid={testid}>{children}</SectionItem>);
}
