import * as React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { ReportDto, ReportStatus } from "../../shared/dtos";
import * as Translation from "../../mf/utils/i18n";

const NavBox = styled.div`
  border: 1px #dedede solid;
`;

const CheckLink = styled(NavLink)<{ status: ReportStatus }>`
  display: block;
  color: ${(props) => props.theme.grey};
  text-decoration: none;
  &:hover, &.active {
    background-color: #fafafa;
  }
  &.active {
    font-weight: bold;
  }
  border-top: 1px #dedede solid;
  padding: 10px 8px;
  &:first-child {
    border-top: none;
    padding-left: 8px;
  }
  &::after {
    float: right;
    ${({ status }) => status === ReportStatus.SUCCESS && `
      content: '✔';
      color: limegreen;
    `}
    ${({ status }) => (status === ReportStatus.FAILURE) && `
      content: '✘';
      color: red;
    `}
    ${({ status, theme }) => (status === ReportStatus.ERROR) && `
      content: '!';
      color: ${theme.danger};
      font-weight: bold;
      margin-right: 4px;
    `}
    ${({ status }) => (status === ReportStatus.PENDING) && `
      content: '?';
      color: blue;
      font-weight: bold;
      margin-right: 4px;
    `}
    ${({ status }) => (status === ReportStatus.PENDING_FINAL) && `
      content: '?';
      color: red;
      font-weight: bold;
      margin-right: 4px;
    `}
    ${({ status }) => (status === ReportStatus.RUNNING) && `
      content: '';
      margin-top: 2.5px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 2px solid #dddddd;
      border-top: 2px solid #888888;
      font-weight: bold;
      animation: load 1s infinite linear;
      @keyframes load {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
    `}
  }
`;

interface Props {
  readonly report: ReportDto,
}

export function CheckList({ report }: Props): React.ReactElement {
  const { t } = Translation.useTranslation();
  return (
    <NavBox>
      <CheckLink to={`/reports/${report.id}`} exact status={report.status}>{t("app:REPORT_PAGE")}</CheckLink>
      <CheckLink to={`/reports/${report.id}/system-purpose`} exact status={report.systemPurpose.status}>◦&nbsp;&nbsp;{t("app:SYSTEM_PURPOSE_CHECK_NAME")}</CheckLink>
      <CheckLink to={`/reports/${report.id}/data-transparency`} exact status={report.dataTransparency.status}>◦&nbsp;&nbsp;{t("app:DATA_TRANSPARENCY_CHECK_NAME")}</CheckLink>
      <CheckLink to={`/reports/${report.id}/data-privacy`} exact status={report.dataPrivacy.status}>◦&nbsp;&nbsp;{t("app:DATA_PRIVACY_CHECK_NAME")}</CheckLink>
      <CheckLink to={`/reports/${report.id}/system-transparency`} exact status={report.systemTransparency.status}>◦&nbsp;&nbsp;{t("app:SYSTEM_TRANSPARENCY_CHECK_NAME")}</CheckLink>
      <CheckLink to={`/reports/${report.id}/system-equality`} exact status={report.systemEquality.status}>◦&nbsp;&nbsp;{t("app:SYSTEM_EQUALITY_CHECK_NAME")}</CheckLink>
    </NavBox>
  );
}
