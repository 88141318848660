import * as React from "react";
import { TFunction, Trans } from "react-i18next";
import * as Translation from "../../../mf/utils/i18n";
import { TextInput } from "../../app/TextInput";
import { SystemTransparencyData } from "../../../shared/dtos";

export const defaults: SystemTransparencyData = {
  details: "",
  balancedInterests: "",
  feedbackLoops: "",
  systemOwner: "",
  technicalDescription: "",
  metrics: "",
  ethical: "",
  modelOwner: "",
  decisionExplanation: "",
  humanIntervention: "",
  modelLogic: "",
  notes: "",
};

export function SystemTransparencyForm(): React.ReactElement {
  const { t } = Translation.useTranslation();
  const tf = t as TFunction;

  return (
    <>
      <h4>{t("app:SYSTEM_TRANSPARENCY_SYSTEM_SECTION_HEADER")}</h4>
      <TextInput
        key="details"
        name="details"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_DETAILS_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_DETAILS_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_DETAILS_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="balancedInterests"
        name="balancedInterests"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_BALANCED_INTERESTS_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_BALANCED_INTERESTS_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_BALANCED_INTERESTS_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="feedbackLoops"
        name="feedbackLoops"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_FEEDBACK_LOOPS_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_FEEDBACK_LOOPS_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_FEEDBACK_LOOPS_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="humanIntervention"
        name="humanIntervention"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_HUMAN_INTERVENTION_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_HUMAN_INTERVENTION_DESCRIPTION" />}
        placeholder={t("app:HUMAN_TRANSPARENCY_FIELD_HUMAN_INTERVENTION_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="ethical"
        name="ethical"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_ETHICAL_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_ETHICAL_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_ETHICAL_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="systemOwner"
        name="systemOwner"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_SYSTEM_OWNER_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_SYSTEM_OWNER_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_SYSTEM_OWNER_PLACEHOLDER")}
        size={3}
      />
      <hr />
      <h4>{t("app:SYSTEM_TRANSPARENCY_MODEL_SECTION_HEADER")}</h4>
      <TextInput
        key="technicalDescription"
        name="technicalDescription"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_TECHNICAL_DESCRIPTION_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_TECHNICAL_DESCRIPTION_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_TECHNICAL_DESCRIPTION_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="metrics"
        name="metrics"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_METRICS_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_METRICS_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_METRICS_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="decisionExplanation"
        name="decisionExplanation"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_DECISION_EXPLANATION_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_DECISION_EXPLANATION_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_DECISION_EXPLANATION_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="modelLogic"
        name="modelLogic"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_MODEL_LOGIC_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_MODEL_LOGIC_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_MODEL_LOGIC_PLACEHOLDER")}
        size={6}
      />
      <TextInput
        key="modelOwner"
        name="modelOwner"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_MODEL_OWNER_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_MODEL_OWNER_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_MODEL_OWNER_PLACEHOLDER")}
        size={3}
      />
      <hr />
      <h4>{t("app:SYSTEM_TRANSPARENCY_OTHER_SECTION_HEADER")}</h4>
      <TextInput
        key="notes"
        name="notes"
        label={t("app:SYSTEM_TRANSPARENCY_FIELD_NOTES_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:SYSTEM_TRANSPARENCY_FIELD_NOTES_DESCRIPTION" />}
        placeholder={t("app:SYSTEM_TRANSPARENCY_FIELD_NOTES_PLACEHOLDER")}
        size={6}
      />
    </>
  );
}
