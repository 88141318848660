import * as React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import { Warning } from "@styled-icons/material-outlined";
import { TFunction, Trans } from "react-i18next";
import * as Translation from "../../mf/utils/i18n";
import { ReportId } from "../../shared/dtos";
import { useAnalytics } from "../../mf/utils/analyticsHooks";

const DialogProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 0 0 0;
`;

const DialogWarning = styled(Button)`
  margin-right: auto;
  svg {
    display: inline-flex;
  }
`;

interface Props {
  readonly closeDialog: () => void,
  readonly id: ReportId,
  readonly onDelete: (id: ReportId) => Promise<void>,
}
export function DeleteReportDialog({ onDelete, closeDialog, id }: Props): React.ReactElement {
  const { t } = Translation.useTranslation();
  const analytics = useAnalytics();
  const tf = t as TFunction;

  const [submitError, setSubmitError] = React.useState<Error | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(async () => {
    setSubmitError(undefined);
    setIsSubmitting(true);
    try {
      await onDelete(id);
      closeDialog();
      analytics.track("report", "deleted", {});
    } catch (e) {
      setSubmitError(e);
    } finally {
      setIsSubmitting(false);
    }
  }, [closeDialog, onDelete, setSubmitError, setIsSubmitting, analytics, id]);

  return (
    <Dialog open onClose={closeDialog} fullWidth>
      <DialogTitle id="simple-dialog-title">{t("app:DELETE_REPORT_DIALOG_TITLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isSubmitting ? (
            <DialogProgress>
              <CircularProgress />
            </DialogProgress>
          ) : (
            <Trans i18nKey="app:DELETE_REPORT_DIALOG_DESCRIPTION" t={tf} />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {submitError && <DialogWarning disabled><Typography color="error"><Warning size={18} /> {submitError.message}</Typography></DialogWarning>}
        <Button onClick={closeDialog} color="default" disabled={isSubmitting}>
          {t("app:DELETE_REPORT_DIALOG_CANCEL_ACTION")}
        </Button>
        <Button type="submit" color="primary" disabled={isSubmitting} onClick={onSubmit} autoFocus>
          {t("app:DELETE_REPORT_DIALOG_DELETE_ACTION")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
