import * as React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { ArrowBackIos } from "@styled-icons/material-outlined";
import { Logo } from "../util/Logo";

const Toggle = styled.div<{ collapsed: boolean }>`
  position: absolute;
  top: 0;
  right: -${(props) => props.theme.navToggleSize}px;
  width: ${(props) => props.theme.navToggleSize}px;
  height: 100%;
  cursor: pointer;
  transform: ${(props) => (props.collapsed ? "scaleX(-1)" : "none")};
  background-color: #1f283100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.greyLight};
  transition: all ease 0.5s;
  z-index: 100;
  & > * {
    transition: all ease 0.5s;
    opacity: 0;
  }
  &:hover {
    background-color: #141b25FF !important;
    color: ${(props) => props.theme.white};
  }
`;

const Frame = styled.div`
  background: ${(props) => props.theme.grey};
  color: ${(props) => props.theme.greyLight};
  transition: all ease 0.3s, flex-basis ease 1s, max-width ease 1s;
  flex-basis: ${(props) => props.theme.navExpandedSize}px;
  max-width: ${(props) => props.theme.navExpandedSize}px;
  &.collapsed {
    flex-basis: ${(props) => props.theme.navCollapsedSize}px;
    max-width: ${(props) => props.theme.navCollapsedSize}px;
  }
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  &:hover > div:first-child {
    background-color: #1f2831FF;
    & > * {
      opacity: 1;
    }
  }
  @media print {
   display: none;
  }
`;

const Header = styled.div`
  padding: ${(props) => props.theme.pad}px;
  padding-bottom: ${(props) => props.theme.pad / 2}px;
  padding-top: 1rem;
  flex: auto 0 0;
  &::after {
    content: "";
    display: block;
    clear: both;
    border-bottom: 1px solid ${(props) => props.theme.greyLight};
  }
`;

const Main = styled.div<{ collapsed: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-height: 0;
  padding: ${(props) => props.theme.pad / 2}px 0 ${(props) => props.theme.pad / 2}px 0;
`;

const Footer = styled.div<{ collapsed: boolean }>`
  padding-top: ${(props) => props.theme.pad / 3}px;
  padding-bottom: ${(props) => props.theme.pad / 3}px;
  padding-left: ${(props) => props.theme.pad}px;
  padding-right: ${(props) => props.theme.pad}px;
  flex: auto 0 0;
  font-size: 9.5px;
  white-space: nowrap;
  overflow: hidden;
  border-top: 1px solid ${(props) => props.theme.greyLight};
  & > :last-child {
    transition: all ease 1s;
    overflow: hidden;
    height: ${(props) => (props.collapsed ? 9.5 : 0)}px;
    opacity: ${(props) => (props.collapsed ? 1 : 0)};
  }
  & > :first-child > span {
    transition: opacity ease 1s;
    opacity: ${(props) => (props.collapsed ? 0 : 1)};
  }
`;

const NavLogo = styled(Logo)<{ collapsed: boolean }>`
  transition: height ease 1s;
  height: ${(props) => props.theme.pad * 1.75}px;
  & .text {
    transition: fill ease 0.3s;
    fill: ${(props) => (props.collapsed ? "transparent" : props.theme.white)};
  }
  & .icon {
    transition: fill ease 0.3s;
    fill: ${(props) => props.theme.white};
  }
`;

const HubIconWrapper = styled.div<{ collapsed: boolean }>`
  display: inline-block;
  transition: margin-bottom ease 0.6s;
  margin-bottom: 1rem;
  a {
    transition: color ease 0.3s;
    color: ${(props) => props.theme.greyLight};
    &:hover {
      color: ${(props) => props.theme.white};
    }
  }
`;

interface Props {
  readonly children?: React.ReactNode,
  readonly initiallyCollapsedState?: boolean,
  readonly companyName: string,
  readonly companyNameShort: string,
  readonly version: string,
}

export function NavigationFrame({ children, initiallyCollapsedState, companyName, companyNameShort, version }: Props): React.ReactElement {
  const [collapsed, setCollapsed] = React.useState<boolean>(initiallyCollapsedState ?? false);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [setCollapsed, collapsed]);
  return (
    <Frame className={collapsed ? "collapsed" : undefined} data-testid={collapsed ? "nav-closed" : "nav-open"}>
      <Toggle collapsed={collapsed} onClick={toggleCollapsed} data-testid="nav-toggle"><ArrowBackIos size={15} /></Toggle>
      <Header>
        <HubIconWrapper collapsed={collapsed}>
          <Link to="/">
            <NavLogo collapsed={collapsed} />
          </Link>
        </HubIconWrapper>
      </Header>
      <Main collapsed={collapsed}>
        {children}
      </Main>
      <Footer collapsed={collapsed}>
        <div>v{version} <span> © {companyName} {new Date().getFullYear()}</span></div>
        <div>© {companyNameShort} {new Date().getFullYear()}</div>
      </Footer>
    </Frame>
  );
}
