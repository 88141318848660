import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Warning } from "@styled-icons/material-outlined";
import * as React from "react";
import styled from "styled-components/macro";
import { Formik, Form, FormikErrors, FormikHelpers, FormikConfig } from "formik";
import * as Translation from "../../mf/utils/i18n";

const SubmitButton = styled(Button)`
  min-width: 120px;
`;

const FormBlock = styled(Form)`
  position: relative;
`;
const FieldSet = styled.fieldset`
  max-width: 100%;
  border: none;
  padding: 0;
  margin: 0;
`;

type Props<T> = FormikConfig<T> & {
  readonly hideTitle?: boolean,
  readonly validateAndTranslate: (request: T, t: (key: string, bag?: { [key: string]: string | number }) => string) => FormikErrors<T>,
};
export function CheckForm<T>({ hideTitle, onSubmit, onReset, validateAndTranslate, children, ...rest }: Props<T>): React.ReactElement {
  const { t } = Translation.useTranslation();
  const [submitError, setSubmitError] = React.useState<Error | undefined>(undefined);

  const validate = React.useCallback((values: T): FormikErrors<T> => validateAndTranslate(values, t), [t, validateAndTranslate]);

  const onSubmitExtra = React.useCallback((values: T, helpers: FormikHelpers<T>): void | Promise<void> => {
    setSubmitError(undefined);
    const promise = onSubmit(values, helpers);
    if (promise !== undefined) {
      return promise.catch((e: Error) => {
        setSubmitError(e);
      });
    }
    return undefined;
  }, [onSubmit, setSubmitError]);

  const onResetExtra = React.useCallback((values: T, helpers: FormikHelpers<T>): void => {
    if (onReset) {
      onReset(values, helpers);
    }
    setSubmitError(undefined);
  }, [onReset, setSubmitError]);

  return (
    <Formik {...rest} onSubmit={onSubmitExtra} onReset={onResetExtra} validate={validate}>
      {({ isSubmitting, isValid }) => (
        <FormBlock>
          {!hideTitle && (<h3 className="center">{t("app:CHECK_PAGE_CONFIGURATION")}</h3>)}
          <FieldSet disabled={isSubmitting}>
            {children}
            <div className="center">
              {submitError && <Typography color="error"><Warning size={18} /> {submitError.message}</Typography>}
              {!isSubmitting && <SubmitButton type="submit" disabled={!isValid} size="large" color="primary" variant="outlined">{t("app:CHECK_PAGE_SUBMIT")}</SubmitButton>}
              {isSubmitting && <CircularProgress size={24} />}
            </div>
          </FieldSet>
        </FormBlock>
      )}
    </Formik>
  );
}
