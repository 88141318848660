import * as React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components/macro";

const SideBySide = styled(Grid)`
  margin-bottom: 8px;
`;
const Explanation = styled(Grid)`
  display: flex;
  & > div {
    flex-grow: 1;
    background-color: #fafafa;
    padding: 0.75em;
  }
`;
interface Props {
  readonly explanation: React.ReactNode,
  readonly children: React.ReactNode,
}
export function FormControl({
  explanation,
  children,
}: Props): React.ReactElement {
  return (
    <SideBySide container spacing={1}>
      <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>{children}</Grid>
      <Explanation item xl={5} lg={5} md={12} sm={12} xs={12}><div>{explanation}</div></Explanation>
    </SideBySide>
  );
}
