import React from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryTheme } from "victory";
import styled from "styled-components/macro";
import { DomainPaddingPropType, DomainPropType, VictoryStyleInterface } from "victory-core";
import { useTranslation } from "../../../mf/utils/i18n";
import { FairnessMetric } from "../../../shared/dtos";

const ChartContainer = styled.div<{ size: number }>`
  max-width: 650px;
  page-break-inside: avoid;
  height: ${(props) => (props.size * 40) + 75}px;
`;

const axisStyle = { axisLabel: { padding: 35 }, grid: { stroke: "none" } };
const tickValues = [0, 0.2, 0.4, 0.6, 0.8, 1];
const lineStyle = { data: { strokeWidth: 2, strokeDasharray: "2,2" } };
const barStyle: VictoryStyleInterface = {
  data: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    fill: ({ datum }: { datum: unknown }) => datum.fill,
  },
};
const padding = { top: 15, bottom: 50, left: 85, right: 85 };
const domainPadding: DomainPaddingPropType = { x: [0, 10], y: [0, 0] };

const fairnessMetricAverageLabel: { [val in FairnessMetric]: string } = {
  "equal-opportunity": "app:EQUALITY_PLOT_AVERAGE_FAIRNESS_METRIC_LABEL_EQUAL_OPPORTUNITY",
  "demographic-parity": "app:EQUALITY_PLOT_AVERAGE_FAIRNESS_METRIC_LABEL_DEMOGRAPHIC_PARITY",
  "average-fairness": "app:EQUALITY_PLOT_AVERAGE_FAIRNESS_METRIC_LABEL_AVERAGE_FAIRNESS",
};

const fairnessMetricYLabel: { [val in FairnessMetric]: string } = {
  "equal-opportunity": "app:EQUALITY_PLOT_FAIRNESS_METRIC_LABEL_EQUAL_OPPORTUNITY",
  "demographic-parity": "app:EQUALITY_PLOT_FAIRNESS_METRIC_LABEL_DEMOGRAPHIC_PARITY",
  "average-fairness": "app:EQUALITY_PLOT_FAIRNESS_METRIC_LABEL_AVERAGE_FAIRNESS",
};

interface Props {
  readonly fairnessMetric: FairnessMetric,
  readonly averageMetric: number,
  readonly columnName: string,
  readonly groups: Record<string, {
    readonly metric: number,
    readonly isFair: boolean,
  }>,
}
export function EqualityPlot({ averageMetric, groups, fairnessMetric, columnName }: Props): React.ReactElement {
  const { t } = useTranslation();
  const data = React.useMemo(() => Object.entries(groups).map(([group, { metric, isFair }]) => ({ x: group, y: metric, fill: isFair ? "#0092D2" : "#e07878" })), [groups]);
  const line = React.useCallback(() => averageMetric, [averageMetric]);
  const lineLabel = React.useMemo(() => ["", t(fairnessMetricAverageLabel[fairnessMetric], { predictionColumn: columnName })], [t, fairnessMetric, columnName]);
  const domain = React.useMemo<DomainPropType>(() => ({ x: [0.5, data.length + 0.45], y: fairnessMetric !== "average-fairness" ? [0, 1] : undefined }), [data, fairnessMetric]);

  return (
    <ChartContainer size={data.length}>
      <VictoryChart
        theme={VictoryTheme.material}
        horizontal
        domain={domain}
        domainPadding={domainPadding}
        width={600}
        height={(data.length * 25) + 75}
        padding={padding}
      >
        <VictoryBar
          data={data}
          barWidth={16}
          style={barStyle}
        />
        <VictoryAxis
          tickCount={data.length}
          style={axisStyle}
        />
        <VictoryAxis
          dependentAxis
          label={t(fairnessMetricYLabel[fairnessMetric], { predictionColumn: columnName })}
          tickValues={fairnessMetric !== "average-fairness" ? tickValues : undefined}
          fixLabelOverlap
          style={axisStyle}
        />
        <VictoryLine
          y={line}
          samples={1}
          style={lineStyle}
          labels={lineLabel}
        />
      </VictoryChart>
    </ChartContainer>
  );
}
