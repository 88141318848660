import * as React from "react";
import { useFormikContext } from "formik";
import { TFunction, Trans } from "react-i18next";
import { DataPrivacyData } from "../../../shared/dtos";
import * as Translation from "../../../mf/utils/i18n";
import { CsvInput } from "../../app/CsvInput";
import { FormColumnBlock } from "../../app/FormColumnBlock";
import { SelectInput } from "../../app/SelectInput";
import { TextInput } from "../../app/TextInput";
import { ColumnStats } from "../../app/ColumnStats";

export const defaults: DataPrivacyData = {
  dataset: { name: undefined, body: undefined, pending: false, columns: undefined },
  idType: {},
  mitigation: {},
  mitigationComments: {},
  notes: "",
};

export function DataPrivacyForm(): React.ReactElement {
  const { t } = Translation.useTranslation();
  const tf = t as TFunction;

  const { values: { dataset: { columns, body } } } = useFormikContext<DataPrivacyData>();

  const onDataSetChangeDependantFieldUpdates = React.useCallback((newColumns: ReadonlyArray<string>): Partial<DataPrivacyData> => ({
    idType: newColumns?.reduce((agg, c) => ({ ...agg, [c]: "na" }), {}) ?? {},
    mitigation: newColumns?.reduce((agg, c) => ({ ...agg, [c]: "none" }), {}) ?? {},
    mitigationComments: newColumns?.reduce((agg, c) => ({ ...agg, [c]: "" }), {}) ?? {},
  }), []);

  return (
    <>
      <CsvInput
        key="dataset"
        name="dataset"
        label={t("app:DATA_PRIVACY_FIELD_DATASET_LABEL")}
        explanation={<Trans t={tf} i18nKey="app:DATA_PRIVACY_FIELD_DATASET_DESCRIPTION" />}
        placeholder={t("app:DATA_PRIVACY_FIELD_DATASET_PLACEHOLDER")}
        onChangeDependantFieldUpdates={onDataSetChangeDependantFieldUpdates}
      />
      {columns ? (
        <>
          {columns.map((column, i) => (
            <FormColumnBlock first={i === 0} key={column}>
              <ColumnStats name={column} data={body!} />
              <SelectInput
                key={`idType.${column}`}
                name={`idType.${column}`}
                label={t("app:DATA_PRIVACY_FIELD_ID_TYPE_LABEL", { column })}
                explanation={<Trans t={tf} i18nKey="app:DATA_PRIVACY_FIELD_ID_TYPE_DESCRIPTION" values={{ column }} />}
              >
                <option key="na" value="na">
                  {t("app:DATA_PRIVACY_FIELD_ID_TYPE_OPTION_NA")}
                </option>
                <option key="direct" value="direct">
                  {t("app:DATA_PRIVACY_FIELD_ID_TYPE_OPTION_DIRECT")}
                </option>
                <option key="quasi" value="quasi">
                  {t("app:DATA_PRIVACY_FIELD_ID_TYPE_OPTION_QUASI")}
                </option>
              </SelectInput>
              <SelectInput
                key={`mitigation.${column}`}
                name={`mitigation.${column}`}
                label={t("app:DATA_PRIVACY_FIELD_MITIGATION_LABEL", { column })}
                explanation={<Trans t={tf} i18nKey="app:DATA_PRIVACY_FIELD_MITIGATION_DESCRIPTION" values={{ column }} />}
              >
                <option key="none" value="none">
                  {t("app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_NONE")}
                </option>
                <option key="pseudo" value="pseudo">
                  {t("app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_PSEUDO")}
                </option>
                <option key="rounding" value="rounding">
                  {t("app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_ROUNDING")}
                </option>
                <option key="truncation" value="truncation">
                  {t("app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_TRUNCATION")}
                </option>
                <option key="categorisation" value="categorisation">
                  {t("app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_CATEGORISATION")}
                </option>
                <option key="noise" value="noise">
                  {t("app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_NOISE")}
                </option>
                <option key="kanonymity" value="kanonymity">
                  {t("app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_KANONYMITY")}
                </option>
                <option key="differential" value="differential">
                  {t("app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_DIFFERENTIAL")}
                </option>
                <option key="other" value="other">
                  {t("app:DATA_PRIVACY_FIELD_MITIGATION_OPTION_OTHER")}
                </option>
              </SelectInput>
              <TextInput
                key={`mitigationComments.${column}`}
                name={`mitigationComments.${column}`}
                label={t("app:DATA_PRIVACY_FIELD_MITIGATION_COMMENTS_LABEL", { column })}
                explanation={<Trans t={tf} i18nKey="app:DATA_PRIVACY_FIELD_MITIGATION_COMMENTS_DESCRIPTION" values={{ column }} />}
                placeholder={t("app:DATA_PRIVACY_FIELD_MITIGATION_COMMENTS_PLACEHOLDER")}
                size={6}
              />
            </FormColumnBlock>
          ))}
          <TextInput
            key="notes"
            name="notes"
            label={t("app:DATA_PRIVACY_FIELD_NOTES_LABEL")}
            explanation={<Trans t={tf} i18nKey="app:DATA_PRIVACY_FIELD_NOTES_DESCRIPTION" />}
            placeholder={t("app:DATA_PRIVACY_FIELD_NOTES_PLACEHOLDER")}
            size={6}
          />
        </>
      ) : (
        <p className="center"><em>{t("app:CHECK_PAGE_DATA_REQUIRED")}</em></p>
      )}
    </>
  );
}
