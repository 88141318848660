import i18next, { PluginOptions } from "i18next";
import { I18nextProvider, initReactI18next, useTranslation as useTranslationReal } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React, { useContext } from "react";
import * as Config from "./config";

interface Language {
  readonly language: string,
  readonly region: string,
  readonly script: string,
  readonly isRtl: boolean,
  readonly name: string,
  readonly localizedName: string,
}

interface Translations {
  readonly projectUrl: string,
  readonly baseLocale: string,
  readonly languages: ReadonlyArray<Language>,
}

export interface Formatters {
  readonly [lang: string]: {
    readonly [format: string]: (value: unknown) => string,
  },
}

interface TranslationFile {
  readonly [key: string]: {
    readonly message: string,
    readonly description?: string,
  },
}
interface FullTranslationBundle {
  readonly [group: string]: TranslationFile,
}
interface TranslationBundle {
  readonly [group: string]: {
    readonly [key: string]: string,
  },
}

const LanguagesContext = React.createContext<ReadonlyArray<{ language: string, name: string }> | null>(null);

type UseTranslationHook = () => {
  readonly t: (key: string, bag?: { [key: string]: unknown }) => string, // readonly t: TFunction, avoid leaking impl
  // readonly i18n: i18n, avoid leaking impl
  readonly languages: ReadonlyArray<{ language: string, name: string }>,
  readonly language: string,
  readonly changeLanguage: (language: string) => void,
};
export const useTranslation: UseTranslationHook = () => {
  const languages = useContext(LanguagesContext)!;
  const actualHook = useTranslationReal();
  return { t: actualHook.t, i18n: actualHook.i18n, languages, language: actualHook.i18n.language, changeLanguage: actualHook.i18n.changeLanguage.bind(actualHook.i18n) };
};

interface I18nGateProps {
  readonly children?: React.ReactNode,
  readonly loadingComponent: React.ComponentType,
}
export function I18nGate({ children, loadingComponent }: I18nGateProps): React.ReactElement {
  const { ready } = useTranslationReal();
  const LoadingComponent = loadingComponent;
  return ready ? (<>{children}</>) : (<LoadingComponent />);
}

export type I18nProviderProps = I18nGateProps;
export function I18nProvider({ loadingComponent, children }: I18nProviderProps): React.ReactElement {
  const { i18n: { approvedLanguages, translations, languageDebug: enableDebug, groups, formatters } } = Config.useBasicConfig();
  const lngs = React.useMemo(() => {
    const baseLanguage = translations.baseLocale;
    const languages = translations.languages.filter((lang) => approvedLanguages.includes(lang.language)).map((lang) => ({
      language: lang.language,
      name: lang.localizedName,
    }));

    const resources: { [lang: string]: TranslationBundle } = {};

    const transformMessages = (messages: TranslationBundle, transform: (text: string) => string) => Object.keys(messages).reduce((agg, ns) => ({
      ...agg,
      [ns]: Object.keys(messages[ns]).reduce((nsAgg, key) => ({ ...nsAgg, [key]: transform(messages[ns][key]) }), {}),
    }), {});
    const extractMessages = (messages: FullTranslationBundle) => Object.keys(messages).reduce((agg, ns) => ({
      ...agg,
      [ns]: Object.keys(messages[ns]).reduce((nsAgg, key) => ({ ...nsAgg, [key]: messages[ns][key].message }), {}),
    }), {});

    translations.languages.map((lang: Language) => lang.language).forEach((lang: string) => {
      if (approvedLanguages.includes(lang)) {
        resources[lang] = extractMessages(groups.reduce((agg, group) => ({
          ...agg,
          // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
          [group]: (require(`../../messages/${lang}/${group}.json`) as TranslationFile),
        }), {}));
      }
    });

    if (enableDebug) {
      languages.push({ language: "spooky", name: "Spooky" });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resources.spooky = transformMessages(resources.en, (message) => `👻 ${message}`);

      languages.push({ language: "confused", name: "Confused" });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resources.confused = transformMessages(resources.en, (message) => message.replaceAll(/[^ ]/gi, "?"));

      languages.push({ language: "cimode", name: "Keys" });
    }

    const detectionOptions: PluginOptions["detection"] = {
      // order and from where user language should be detected
      order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: enableDebug ? [] : ["cimode"], // languages to not persist (cookie, localStorage)

      cookieOptions: { sameSite: "strict", secure: true },
    };

    const promise = () => i18next
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        detection: detectionOptions,
        fallbackLng: baseLanguage,
        supportedLngs: Object.keys(resources),
        nonExplicitSupportedLngs: false,
        react: {
          transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
          transKeepBasicHtmlNodesFor: ["br", "p", "b", "strong", "i", "em"],
        },
        interpolation: {
          format(value, format, language) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const fallback = `${value}`;
            if (format === undefined || language === undefined) return fallback;
            const formatter = (formatters[language] ?? formatters.en)[format] ?? formatters.en[format];
            return formatter === undefined ? fallback : formatter(value);
          },
          formatSeparator: "|",
          escapeValue: false, // not needed for react!!
          skipOnVariables: true,
        },
        resources,
      });

    // eslint-disable-next-line no-console,@typescript-eslint/no-floating-promises
    promise().catch((e) => console.error(e));
    return languages;
  }, [approvedLanguages, formatters, enableDebug, groups, translations]);

  return (
    <LanguagesContext.Provider value={lngs}>
      <I18nextProvider i18n={i18next}>
        <I18nGate loadingComponent={loadingComponent}>
          {children}
        </I18nGate>
      </I18nextProvider>
    </LanguagesContext.Provider>
  );
}
export interface I18nConfig {
  languageDebug: boolean,
  approvedLanguages: ReadonlyArray<string>,
  groups: ReadonlyArray<string>,
  formatters: Formatters,
  translations: Translations,
}
