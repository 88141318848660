import * as React from "react";
import { Comment } from "../../shared/dtos";
import { useTranslation } from "../../mf/utils/i18n";

interface Props {
  readonly comments: ReadonlyArray<Comment>,
}

export function ReportComments({ comments }: Props): React.ReactElement {
  const { t } = useTranslation();
  if (comments.length === 0) {
    return (<>{t("app:REPORT_TEXT_COMMENTS_NONE")}</>);
  }
  return (
    <>
      {comments.map((comment) => (<>{t("app:REPORT_TEXT_COMMENTS_FORMAT", { ...comment })}<br /></>))}
    </>
  );
}
