import * as React from "react";
import styled from "styled-components/macro";
import { DropDownSectionHeader } from "../dropDown/DropDownSectionHeader";
import { DropDownSectionItem } from "../dropDown/DropDownSectionItem";
import { DropDownDivider } from "../dropDown/DropDownDivider";
import { DropDownItem } from "../dropDown/DropDownItem";
import { DropDown } from "../dropDown/DropDown";
import * as Translation from "../../../utils/i18n";
import * as Auth from "../../../utils/auth";

const Banner = styled.div`
  position: absolute;
  height: 0;
  width: 0;
  top:0;
  right: 0;

  @media print {
    display: none;
  }
`;

const UserDropDown = styled(DropDown)<{ collapsed: boolean }>`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  display: inline-block;
  margin-bottom: ${(props) => props.theme.pad}px;
  transition: margin-top ease 0.6s;
  margin-top: ${(props) => (props.collapsed ? 65 : 0)}px;
  z-index:1000;
`;

const User = styled.div<{ picture?: string }>`
  width: 45px;
  height: 45px;
  border-radius: 22.5px;
  background-color: ${(props) => props.theme.greyLight};
  background-image: url(${(props) => props.picture});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1.5px solid ${(props) => props.theme.greyLight};
  cursor: pointer;
  transition: border-color ease 0.3s;
  &:hover {
    border-color: ${(props) => props.theme.white};
  }
`;

interface Props {
  readonly initiallyCollapsedState?: boolean,
  readonly languageHeader: string,
  readonly logoutAction: string,
}

export function BannerOverlay({ initiallyCollapsedState, languageHeader, logoutAction }: Props): React.ReactElement {
  const [collapsed] = React.useState<boolean>(initiallyCollapsedState ?? false);
  const { language, languages, changeLanguage } = Translation.useTranslation();
  const auth = Auth.useAuth();
  return (
    <Banner>
      {auth.user && (
        <UserDropDown collapsed={collapsed} target={<User picture={auth.user.picture} />} testid="user-menu">
          <DropDownSectionHeader>{languageHeader}</DropDownSectionHeader>
          {languages.map((lang) => (
            <DropDownSectionItem key={lang.language} testid={`lang-${lang.language}`} selected={lang.language === language} onClick={() => changeLanguage(lang.language)}>{lang.name}</DropDownSectionItem>
          ))}
          <DropDownDivider />
          <DropDownItem onClick={auth.logout} danger testid="logout-action">{logoutAction}</DropDownItem>
        </UserDropDown>
      )}
    </Banner>
  );
}
