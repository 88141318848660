import * as React from "react";
import { CheckForm } from "../../app/CheckForm";
import { Check, SystemEqualityData, SystemEqualityFinal, SystemEqualityResult } from "../../../shared/dtos";
import { SystemEqualityReportContent } from "./SystemEqualityReportContent";
import { validateSystemEqualityFinal } from "../../../shared/validation";

interface Props {
  readonly data: Check<SystemEqualityData, SystemEqualityResult, SystemEqualityFinal>,
  readonly onSubmitFinal: (values: SystemEqualityFinal) => Promise<void>,
}
export function SystemEqualityFinalForm({ data, onSubmitFinal }: Props): React.ReactElement {
  const { columns } = data.out!;
  const initialValues = React.useMemo<SystemEqualityFinal>(() => ({
    justifications: Object.entries(columns).reduce((agg, [col, { groups }]) => ({ ...agg, [col]: Object.keys(groups).reduce((aggg, group) => ({ ...aggg, [group]: "" }), {}) }), {}),
    overrideFair: Object.entries(columns).reduce((agg, [col, { groups }]) => ({ ...agg, [col]: Object.keys(groups).reduce((aggg, group) => ({ ...aggg, [group]: false }), {}) }), {}),
  }), [columns]);

  return (
    <CheckForm initialValues={initialValues} onSubmit={onSubmitFinal} validateAndTranslate={validateSystemEqualityFinal} hideTitle>
      <SystemEqualityReportContent data={data} showFinal />
    </CheckForm>
  );
}
